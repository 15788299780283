import React from "react";
import ReusableBanner from "../Reusable/ReusableBanner";
import CommingSoon from "../../CommingSoon";
import WhyStudio from "../Reusable/WhyStudio";
import ServiceSection from "../Reusable/ServiceSection";
import WhyUs from "../Reusable/WhyUs";
import CallToAction from "../Reusable/CallToAction";
import {Helmet} from "react-helmet";


const MusicDistribution = () => {
  const services = [
    {
      title: "Comprehensive Distribution Solutions",
      image: "/Images/md/distribute.png",
      description:
        "We offer comprehensive music distribution services tailored to meet the unique needs of independent artists, bands and record labels in Delhi. Whether you're launching a single, EP or album, our expert team ensures your music reaches major online stores and streaming platforms worldwide.",
   
    },
    {
      title: "Global Reach",
      image: "/Images/md/global-network.png",
      description:
        ": With our extensive network and partnerships, we ensure your music is available on leading platforms such as Spotify, Apple Music, Amazon Music and more. Expand your fanbase globally with our targeted distribution strategies.",
    
    },
    {
      title: "Royalty Collection",
      image: "/Images/md/customer-royalty.png",
      description:
        "Studio Final Take handles all aspects of royalty collection, ensuring that you receive your earnings promptly and accurately from every stream and download of your music.",
   
    },
    {
      title: "Marketing Support",
      image: "/Images/md/customer-support.png",
      description:
        "Beyond distribution, we provide marketing support to enhance visibility and engagement. From social media campaigns to playlist placements, we help maximize your music's reach and impact.",
     
    },
  ];

  const points = [
    {
      title: "Expertise",
      description:
        "With years of experience in the music industry, Studio Final Take understands the intricacies of digital distribution and ensures seamless delivery of your music to listeners worldwide.",
    },
    {
      title: "Tailored Solutions",
      description:
        "We offer personalized solutions that cater to the specific goals and preferences of each artist or label, empowering them to focus on their creativity while we handle the distribution logistics.",
    },
    {
      title: "Transparent Process",
      description:
        "Our transparent approach means you stay informed at every step, from uploading your tracks to tracking their performance across platforms.",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>Premier Music Distribution Studio in Delhi | Studio Final Take</title>
        <meta
          name="title"
          content="Premier Music Distribution Services in Delhi | Studio Final Take"
        />
        <meta
          name="description"
          content="Studio Final Take provides top-tier music distribution services in Delhi, ensuring broad audience reach across digital platforms and physical media."
        />
        <link
          rel="canonical"
          href="https://www.studiofinaltake.com/music-distribution-company"
        />
        <meta
          property="og:title"
          content="Premier Music Distribution Services in Delhi | Studio Final Take"
        />
        <meta
          property="og:description"
          content="Studio Final Take provides top-tier music distribution services in Delhi, ensuring broad audience reach across digital platforms and physical media."
        />
        <meta
          property="og:url"
          content="https://www.studiofinaltake.com/music-distribution-company"
        />
        <meta property="og:site_name" content="STUDIO FINAL TAKE" />
        <meta property="og:type" content="Local Business" />
        <meta
          name="keywords"
          content="music recording studio in delhi, mixing and mastering studio in delhi, best music recording studio, mastering studio in new delhi, recording studio in south west delhi, song recording studio in new delhi"
        />
      </Helmet>
      <ReusableBanner
        headone="Amplify your music"
        headtwo="enchant your listeners!"
         banner="/Images/banner/music-distribution.jpg"
        para="At Studio Final Take, we understand that getting your music heard is as crucial as creating it. As one of Delhi's leading providers of music distribution services, we specialize in connecting artists and musicians with global audiences through strategic digital platforms."
      />
      <WhyStudio
        img_two="/Images/music-distributions.jpg"
        img_circle="/Images/testnew.png"
        head_sec="Premier Music Distribution Services in Delhi"
        para_sec="At Studio Final Take, we understand that getting your music heard is as crucial as creating it. As one of Delhi's leading providers of music distribution services, we specialize in connecting artists and musicians with global audiences through strategic digital platforms."
        img_one="https://www.media.istockphoto.com/id/540834826/photo/media-concept-smart-tv.jpg?s=612x612&w=0&k=20&c=FD_LRrdX68MlW2nyWGKRyw_xcLqqIxkbCSAU_0Fu2tM="
      />
      <ServiceSection
        heading="Studio Final Take offers a comprehensive range of music distribution services designed to cater to diverse client needs:"
        services={services}
      />

      <WhyUs
        heading="Here’s what sets us apart as the best music distribution service provider in Delhi:"
        points={points}
        image="https://www.templates.hibootstrap.com/atoli/default/assets/img/faq/faq-img3.jpg "
      />
      <CallToAction />
      {/* <CommingSoon/> */}
    </div>
  );
};

export default MusicDistribution;
