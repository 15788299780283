import { Container, Row, Col } from "react-bootstrap";
import "./About.css";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import SectionThird from "../Home/sectionThird/SectionThird";
import SectionTwo from "../Home/sectionTwo/SectionTwo";
import Equipment from "./Equipment";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaAngleRight, FaCaretRight, FaStar } from "react-icons/fa";
import CallToAction from "../Reusable/CallToAction";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import Gallery from "./Gallery";
import Testimonial from "../Home/testimonial/Testimonial";
import { Helmet } from "react-helmet";
const About = () => {
  return (
    <div className="about_section_container">
      <Helmet>
        <title>Top Music Recording Studio in Delhi | Studio Final Take</title>
        <meta
          name="description"
          content="Studio Final Take, the top music recording studio in Delhi, offers state-of-the-art facilities for your music recording needs. Experience excellence in sound at our studio."
        />
        <link rel="canonical" href="https://www.studiofinaltake.com/about" />
        <meta
          property="og:title"
          content="Top Music Recording Studio in Delhi | Studio Final Take"
        />
        <meta
          property="og:description"
          content="Studio Final Take, the top music recording studio in Delhi, offers state-of-the-art facilities for your music recording needs. Experience excellence in sound at our studio."
        />
        <meta property="og:url" content="https://www.studiofinaltake.com/about" />
        <meta property="og:site_name" content="STUDIO FINAL TAKE" />
        <meta property="og:type" content="Local Business" />
        <meta name="keywords" content="music recording studio in delhi" />
        <meta name="keywords" content="mixing and mastering studio in delhi" />
        <meta name="keywords" content="best music recording studio" />
        <meta name="keywords" content="mastering studio in new delhi" />
        <meta name="keywords" content="recording studio in south west delhi" />
        <meta name="keywords" content="song recording studio in new delhi" />
      </Helmet>
      <div>
        <div
          className="about-sec-one-img"
          style={{ background: "url(/Images/Guitars.jpg)" }}
        >
          <div className="overlay overlay-bold overlay-about"></div>
          <div className="about-sec-one-main">
            <h1 className="about-headone">Studio</h1>
            <h6 className="about-sex-headsix">
              <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                {" "}
                Home
              </Link>{" "}
              <FaAngleRight /> Studio
            </h6>
          </div>
        </div>
      </div>
      <div className="section pt-16 pb-10">
        <Container>
          <Row>
            <Col md={6} sm={6}>
              <h2 className="section-title fz-40">
                A Hub for Musical Excellence
              </h2>
              <p>
                At Studio Final Take, music is more than just a passion—it's a
                way of life. Our state-of-the-art music recording studio is
                equipped with the latest technology to provide an unparalleled
                recording experience. From budding artists to established
                musicians, our studio caters to all, ensuring that each
                recording session captures the true essence of the artist's
                vision. Our expert sound engineers and producers work closely
                with clients to bring their musical ideas to life, whether it’s
                a single track or a full-length album.
              </p>
              <div className="mt-4" />
              {/* <button href="#" target="_self" className="button-2-about">
                 Hire Us Now
              </button> */}
              {/* <h2 className="section-title fz-40">Final Take Production</h2> */}
              <p>
                {" "}
                Studio Final Take is a distinguished music studio and film
                production house based in Delhi, renowned for delivering
                top-tier music recording, mixing and mastering services and
                dubbing. With a mission to nurture creativity and excellence in
                the music and film industries, Studio Final Take has established
                itself as a beacon for artists and filmmakers seeking
                high-quality production services.
              </p>
              <div className="mb-9" />
            </Col>
            <Col md={6} sm={6}>
              <div className="text-center-xs">
                <img src="/Images/studio-final-take-delhi.jpg" alt="studio final take delhi" className="mb-3 about_section_imgg" />
              </div>
            </Col>
           
          </Row>
         
        </Container>
      </div>
      <div className="about-section-containerr">
        <div className="about-us-second" style={{backgroundImage:'url(/Images/about-page-parallax-img.jpg)'}}>
          <Container className="rating-view">
            <Row>
              <Col xs={12} sm={6} md={4} className="p0-2">
                <div className="rat_sec1">
                  <p className="title">
                    <span>Studio Final Take</span>
                  </p>
                  <div className="rat_left">
                    <p className="rat_p">
                      <strong>4.9</strong>
                    </p>
                    <p className="fs15 osons white ttu text-center">Rating</p>
                  </div>
                  <div className="rat_right">
                    <p />
                    <FaStar color="#fde16d" size={30} />
                    <FaStar color="#fde16d" size={30} />
                    <FaStar color="#fde16d" size={30} />
                    <FaStar color="#fde16d" size={30} />
                    <FaRegStarHalfStroke color="#fde16d" size={30} />

                    <p />
                    {/* <p
                    className="fs12 osons weight300 white ttu mt5"
                    id="post_result"
                  >
                    Rate Us
                  </p> */}
                  </div>
                  <div className="clearfix" />
                </div>
              </Col>

              <Col xs={4} sm={6} md={2} className="p0-2">
                <div className="rat_sec2">
                  <span className="dn">
                    <strong>4.9</strong>
                  </span>
                  <p className="title2">Out of</p>
                  <p className="rat2_p">
                    <strong>5.0</strong>
                  </p>
                </div>
              </Col>

              <Col xs={4} sm={6} md={3} className="p0-2">
                <div className="rat_sec3">
                  <p className="title2">experience</p>
                  <p className="rat2_p">
                    <span className="dn">26</span>
                    <span>5+ years</span>
                  </p>
                </div>
              </Col>
              <Col xs={4} sm={6} md={3} className="p0-2">
                <div className="rat_sec4">
                  <p className="title2">Videos</p>
                  <p className="rat2_p">
                    <span className="dn">15000</span>
                    <span>500+</span>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Gallery />
      <SectionTwo />

      <Equipment />
      <Testimonial />
      <CallToAction />
    </div>
  );
};

export default About;
