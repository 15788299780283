import React from "react";
import Hero from "./Heros/Hero";
import Section from "./sectionone/Section";
import SectionTwo from "./sectionTwo/SectionTwo";
import SectionThird from "./sectionThird/SectionThird";
import Sections from "./sectionfour/Section";
import SectionFive from "./sectionfive/SectionFive";
import SectionSix from "./sectionsi/SectionSix";
import SectionSeven from "./sectionseven/SectionSeven";
import SectionNine from "./sectionnine/SectionNine";
import SectionTen from "./sectioonTen/SectionTen";
// import Testimonial from "./testimonials/Testimonial";
import Location from "./location/Location";
import Testimonial from "./testimonial/Testimonial";
import Faq from "./Faq/Faq";
import QASection from "./QASection/QASection";
import Gallery from "../AboutUs/Gallery";

const Home = () => {
  return (
    <div className="">
      <Hero />
      {/* <Hero /> */}
      <div class="vc_row-full-width vc_clearfix"></div>
      <SectionSix />
      <div style={{ background: "#f9f9f98f" }} className="gallery-home-page">
        <Gallery />
      </div>
      <SectionSeven />
      <SectionTwo />

      {/* <Section /> */}
      <SectionNine />

      <QASection />
      {/* <Faq/> */}
      {/* <div className="pt-5 mt-5"> */}
      <Testimonial />
      {/* <Testimonial /> */}
      {/* <SectionTen /> */}
      <SectionThird />
      <Location />
      {/* </div>   */}

      {/* <Sections/> */}
      {/* <SectionFive/> */}
    </div>
  );
};

export default Home;
