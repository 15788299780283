import React from "react";
import ReusableBanner from "../Reusable/ReusableBanner";
import CommingSoon from "../../CommingSoon";
import WhyStudio from "../Reusable/WhyStudio";
import ServiceSection from "../Reusable/ServiceSection";
import WhyUs from "../Reusable/WhyUs";
import CallToAction from "../Reusable/CallToAction";
import { Helmet } from "react-helmet";
const ModelManagement = () => {
  const services = [
    {
      title: "Talent Representation",
      alt:"talent representation",
      image: "/Images/model-management/talent-representation.png",
      description:
        "We specialize in scouting, grooming, and representing talented models who possess the potential to excel in the fashion and entertainment industries.",
    },
    {
      title: "Portfolio Development",
      image: "/Images/model-management/portfolio-development.png",
      alt:"portfolio development",
      description:
        "Our team collaborates with models to create compelling portfolios that showcase their unique strengths and versatility, essential for making a lasting impression in the competitive industry.",
    },
    {
      title: "Casting and Auditions",
      image: "/Images/model-management/casting-and-auditions.png",
      alt:"casting and auditions",
      description:
        "Studio Final Take facilitates casting calls and auditions, connecting models with renowned photographers, designers, and brands looking for fresh faces and established talent.",
    },
    {
      title: "Career Guidance",
      image: "/Images/model-management/career-guidance.png",
      alt:"career guidance",
      description:
        "We offer personalized career guidance and mentorship to models, helping them navigate the complexities of the industry and make informed decisions for their professional growth.",
    },
    {
      title: "Brand Collaborations",
      image: "/Images/model-management/brand-collaborations.png",
      alt:"brand collaborations",
      description:
        "We foster strategic partnerships between models and brands, ensuring mutually beneficial collaborations that enhance brand visibility and credibility.",
    },
  ];

  const points = [
    {
      title: "Industry Expertise",
      description:
        "With years of experience in the fashion and entertainment sectors, our team possesses in-depth industry knowledge and insights, guiding models towards sustainable success.",
    },
    {
      title: "Personalized Approach",
      description:
        "We believe in individualized attention and tailor our services to meet the unique goals and aspirations of each model we represent, fostering a supportive and empowering environment.",
    },
    {
      title: "Extensive Network",
      description:
        "Studio Final Take boasts a robust network of industry professionals, including photographers, stylists, casting directors and designers, facilitating valuable opportunities for our models.",
    },
    {
      title: "Professionalism",
      description:
        "We uphold the highest standards of professionalism and integrity in all our dealings, ensuring transparent communication and ethical practices at every step of the model management process.",
    },
    {
      title: "Track Record of Success",
      description:
        "Our track record speaks for itself, with numerous success stories of models who have achieved significant milestones under our guidance and representation.",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>
          Best Model Management Services in Delhi | Studio Final Take
        </title>
        <meta
          name="description"
          content="Studio Final Take offers top-tier model management services in Delhi, ensuring exceptional guidance and opportunities for aspiring models."
        />
        <link
          rel="canonical"
          href="https://www.studiofinaltake.com/model-management-company"
        />
        <meta
          property="og:title"
          content="Best Model Management Services in Delhi | Studio Final Take"
        />
        <meta
          property="og:description"
          content="Studio Final Take offers top-tier model management services in Delhi, ensuring exceptional guidance and opportunities for aspiring models."
        />
        <meta
          property="og:url"
          content="https://www.studiofinaltake.com/model-management-company"
        />
        <meta property="og:site_name" content="STUDIO FINAL TAKE" />
        <meta property="og:type" content="Local Business" />
        <meta
          name="keywords"
          content="music recording studio in delhi, mixing and mastering studio in delhi, best music recording studio, mastering studio in new delhi, recording studio in south west delhi, song recording studio in new delhi"
        />
      </Helmet>
      <ReusableBanner
        headone="Enhance your image"
        headtwo="captivate the industry!"
        banner="/Images/banner/modal-management-company-banner.jpeg"
        para="Studio Final Take excels in offering top-tier model management services in Delhi, catering to both aspiring and established models. With a focus on professionalism and industry expertise, we provide comprehensive support to nurture careers and secure opportunities. Our dedicated team ensures personalized guidance, strategic career development and access to a wide network of industry contacts. Whether you're seeking representation or aiming to enhance your modeling career, Studio Final Take is committed to delivering exceptional service and fostering success in the competitive world of fashion and modeling. Discover why we're renowned for the best model management services in Delhi."
      />
      <WhyStudio
        img_two="/Images/model-management-service-two.jpg"
        alt_one="model management service"
        alt_two="model management service"
        img_circle="/Images/testnew.png"
        head_sec="Best Model Management Service in Delhi"
        para_sec="Studio Final Take is a leading name in model management services, dedicated to nurturing and representing talent across Delhi. With a commitment to professionalism and excellence, we offer comprehensive model management solutions tailored to meet the diverse needs of our clients."
        img_one="/Images/model-management-service-one.jpg"
      />

      <ServiceSection
        heading="Best Model Management Service in Delhi"
        services={services}
      />
      <WhyUs
        heading="Discover why Studio Final Take stands out as the preferred choice for model management services in Delhi:"
        points={points}
        image="https://www.templates.hibootstrap.com/atoli/default/assets/img/faq/faq-img3.jpg "
      />
      {/* <CommingSoon/> */}

      <CallToAction />
    </div>
  );
};

export default ModelManagement;
