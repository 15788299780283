import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const Main = () => {
  return (
    <div>
      <div>
        <div
          className="about-sec-one-img"
          style={{ background: "url(/Images/Guitars.jpg)" }}
        >
          <div className="overlay overlay-bold overlay-about"></div>
          <div className="about-sec-one-main">
            {/* <h6 className="about-sex-headsix">before and after</h6> */}
            <h1 className="about-headone">Projects</h1>
            <h6 className="about-sex-headsix">
              <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                {" "}
                Home
              </Link>{" "}
              <FaAngleRight /> Project
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
