import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Sound.css";
import WhyStudio from "../Reusable/WhyStudio";
import CallToAction from "../Reusable/CallToAction";
import ServiceSection from "../Reusable/ServiceSection";
import WhyUs from "../Reusable/WhyUs";
import Conclusion from "../Reusable/Conclusion";
import { Helmet } from "react-helmet";

const SoundRecording = () => {
  const services = [
    {
      title: "Music Recording",
      alt:'sound recoding',
      image: "/Images/sound-recoding.png",
      description:
        "Our studio is equipped to handle all aspects of music recording, from solo artists to full bands. With cutting-edge technology and acoustically treated rooms, we capture every nuance of your performance.",
    },
    {
      title: "Voice-Over Recording",
      alt:'voice over recording',
      image: "/Images/voice-over-recording.png",
      description:
        "For voice-over artists, we provide a quiet and comfortable environment where you can deliver your best work. Our engineers ensure that every word is captured with clarity and precision.",
    },
    {
      title: "Podcast Recording",
      alt:'podcast recording',
      image: "/Images/podcast-recording.png", // Replace with the correct path
      description:
        "Podcasts are a booming medium and we offer specialized recording services to ensure your podcast sounds professional and engaging. Our studio provides the perfect setting for clear, high-quality audio.",
    },
    // Add more services as needed
  ];

  const points = [
    {
      title: "State-of-the-Art Equipment",
      description:
        "We invest in the latest recording technology to ensure our clients have access to the best tools available. From high-end microphones to advanced mixing consoles, our equipment is top-notch.",
    },
    {
      title: "Experienced Professionals",
      description:
        "Our team of sound engineers and producers brings years of experience to the table. They have worked with a wide range of genres and projects, ensuring they can meet your specific needs with expertise and creativity.",
    },
    {
      title: "Acoustically Treated Studios",
      description:
        "The design of our recording spaces plays a crucial role in the quality of the sound we capture. Our studios are acoustically treated to minimize unwanted noise and reflections, providing a clean and accurate recording environment.",
    },
    {
      title: "Flexible Booking Options",
      description:
        "We understand that every project is unique and we offer flexible booking options to accommodate your schedule. Whether you need a few hours or several days, we can tailor our services to fit your requirements.",
    },
    {
      title: "Affordable Rates",
      description:
        "Quality sound recording shouldn't break the bank. At Studio Final Take, we offer competitive pricing without compromising on the quality of our services.",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>
          Best Sound Recording Studio in Delhi | Studio Final Take
        </title>
        <meta
          name="description"
          content="Studio Final Take offers the best sound recording services in Delhi. Our state-of-the-art facilities and expert team ensure top-quality recordings for all your projects."
        />
        <link
          rel="canonical"
          href="https://www.studiofinaltake.com/sound-recording-studio"
        />
        <meta
          property="og:title"
          content="Best Sound Recording Studio in Delhi | Studio Final Take"
        />
        <meta
          property="og:description"
          content="Studio Final Take offers the best sound recording services in Delhi. Our state-of-the-art facilities and expert team ensure top-quality recordings for all your projects."
        />
        <meta
          property="og:url"
          content="https://www.studiofinaltake.com/sound-recording-studio"
        />
        <meta property="og:site_name" content="STUDIO FINAL TAKE" />
        <meta property="og:type" content="Local Business" />
        <meta
          name="keywords"
          content="music recording studio in delhi, mixing and mastering studio in delhi, best music recording studio, mastering studio in new delhi, recording studio in south west delhi, song recording studio in new delhi"
        />
      </Helmet>
      <div className="banner__area" style={{background:'url(/Images/banner/sound-recording-studio-banner.jpg)'}}>
        <div className="noverlay"></div>
        <Container>
          <Row>
            <Col xl={12}>
              <div className="banner__area-title">
                <div>
                  Elevate Your Sound,<span>Captivate Your Audience</span>
                </div>
                <p className="sound-record-firstp">
                  Studio Final Take offers the best sound recording services in
                  Delhi, providing high-quality audio production for musicians,
                  voice-over artists and commercial projects. Our
                  state-of-the-art facilities and experienced sound engineers
                  ensure flawless recordings, capturing every nuance of your
                  performance. Whether you're looking for professional music
                  recording, voice-over sessions or post-production work, our
                  studio delivers top-notch sound recording services in Delhi.
                  At Studio Final Take, we are dedicated to bringing your audio
                  visions to life with precision and excellence. Choose us for
                  the finest sound recording services in Delhi, where quality
                  meets creativity.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <WhyStudio
        img_two="/Images/sound-recording-service-1.jpg"
        alt_two='sound recording service'
        img_circle="/Images/testnew.png"
        head_sec="Best Sound Recording Studio in Delhi"
        para_sec="When it comes to best sound recording services in Delhi, Studio Final Take stands out as the go-to choice for artists, producers and creatives seeking top-tier sound recording services. Our state-of-the-art facilities, coupled with our team of experienced professionals, ensure that every recording session meets the highest standards of quality and creativity. "
        img_one="/Images/sound-recording-service-2.jpg"
        alt_one='sound recording service'
      />

      {/* <div className="sound-rec-div2">
        <Container>
          <Row className="feature-row g-0 position-right">
            <Col lg={4} className="image-column">
              <div className="inner-column">
                <div className="image-box">
                  <div className="image overlay-anim">
                    <div className="service-featured-img">
                      <img
                        src="https://www.finaltakeproduction.com/public/frontend/assets/images/sound-production3.jpg"
                        width={570}
                        height={435}
                        alt=""
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={8} className="content-column">
              <div className="inner-column">
                <div className="content-box">
                  <div className="sec-title">
                    <h5 className="service-subtitle">DISCOVER</h5>
                    <h2 className="service-title">Live Instrument Recording</h2>
                    <div className="service-details">
                      From the subtle nuances of a solo instrument to the
                      dynamic energy of a full ensemble, our skilled engineers
                      specialize in capturing the magic of live performances
                      with precision and clarity. Your voice is unique, and
                      we're committed to ensuring it shines. Our vocal recording
                      sessions are tailored to highlight the subtleties and
                      emotions of your performance, whether it's a powerful
                      anthem or an intimate ballad.
                    </div>
                  </div>

                
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="sound-rec-div2">
        <Container>
          <Row className="feature-row g-0 position-left">
            <Col lg={4} className="image-column">
              <div className="inner-columnss">
                <div className="image-box">
                  <div className="image overlay-anim">
                    <div className="service-featured-img">
                      <img
                        src="https://www.finaltakeproduction.com/public/frontend/assets/images/sound-production3.jpg"
                        width={570}
                        height={435}
                        alt=""
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={8} className="content-column">
              <div className="inner-columnss">
                <div className="content-box">
                  <div className="sec-title">
                    <h5 className="service-subtitle">DISCOVER</h5>
                    <h2 className="service-title">Cutting-Edge Technology</h2>
                    <div className="service-details">
                      Our studio is equipped with the latest in recording
                      technology, ensuring that every note is captured with
                      precision and clarity. Our team of experienced sound
                      engineers is passionate about music. They bring a wealth
                      of knowledge and technical expertise to each recording
                      session, ensuring your music is in capable hands. We
                      understand that every project is unique. Our team works
                      closely with you to tailor our recording services to meet
                      your specific needs and artistic vision.
                    </div>
                  </div>

               
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="sound-rec-div2">
        <Container>
          <Row className="feature-row g-0 position-right">
            <Col lg={4} className="image-column">
              <div className="inner-column">
                <div className="image-box">
                  <div className="image overlay-anim">
                    <div className="service-featured-img">
                      <img
                        src="https://www.finaltakeproduction.com/public/frontend/assets/images/sound-production3.jpg"
                        width={570}
                        height={435}
                        alt=""
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={8} className="content-column">
              <div className="inner-column inner-column-third">
                <div className="content-box">
                  <div className="sec-title">
                    <h5 className="service-subtitle">DISCOVER</h5>
                    <h2 className="service-title">
                      Comfortable and Creative Environment
                    </h2>
                    <div className="service-details">
                      Our studio provides a comfortable and creative space where
                      artists can feel at ease and focus on their craft. The
                      studio's acoustics and atmosphere are designed to inspire
                      exceptional performances. Contact us today to schedule a
                      consultation and discuss how Final Take Productions can
                      elevate the sound of your music project. Let's embark on a
                      sonic journey together, creating a recording that
                      resonates with your audience and stands the test of time.
                    </div>
                  </div>

                
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      <ServiceSection
        heading="Our Services on the Global Market for Our Client's Reservation"
        services={services}
      />
      <WhyUs
        heading="When it comes to finding the best sound recording services in Delhi, several factors set Studio Final Take apart from the competition."
        points={points}
        image="https://www.templates.hibootstrap.com/atoli/default/assets/img/faq/faq-img3.jpg "
      />
      <Conclusion />
      <CallToAction />
      {/* <div
        className="content-section  parallax-section hidden-section dark-bg"
        data-scrollax-parent="true"
        id="sec8"
      >
        <div
          className="sr-bgs par-elem sr-fouth-div-img"
        //   data-bg="https://storage.googleapis.com/pr-newsroom-wp/1/2022/12/Header.jpg"
        //   data-scrollax="properties: { translateY: '20%' }"
          style={{
            // backgroundImage: 'url("https://storage.googleapis.com/pr-newsroom-wp/1/2022/12/Header.jpg")',
            transform: "translateZ(0px) translateY(-3.13713%)",
          }}
       
        />
        <div className="overlay overlay-bold" />
        <div className="container">
          <div className="section-title-fourth">
            <h4>Call to Us for Your Perfect Sound</h4>
            <h2>Ready to elevate your audio experience? Our team at Final Take Production is passionate about bringing your sound to life.</h2>
            <div className="section-separator">
             <button className="sr-record-btnn">Get Your Sound Recorded</button>
            </div>
          </div>
        </div>
        <div className="dec-corner dc_lb" />
        <div className="dec-corner dc_rb" />
        <div className="dec-corner dc_rt" />
        <div className="dec-corner dc_lt" />
      </div> */}

      {/* <div>
      <Container>
      <div className="row feature-row g-0 position-left">
  <div className="image-column col-lg-4">
    <div className="inner-column">
      <div className="image-box">
        <div className="image overlay-anim">
          <div className="service-featured-img">
            <img
              loading="lazy"
              decoding="async"
              src="https://dev24.kodesolution.com/hoexr/wp-content/uploads/2023/11/feature-3.png"
              width={570}
              height={435}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="content-column col-lg-8">
    <div className="inner-column">
      <div className="content-box">
        <div className="sec-title">
          <h5 className="service-subtitle">EXPERIENCES</h5>
          <h2 className="service-title">Spa Center</h2>
          <div className="service-details">
            Restaurant inilla duiman at elit finibus viverra nec a lacus themo
            the nesudea seneoice misuscipit non sagie the fermen ziverra
            tristiue duru the ivite dianne onen nivami acsestion augue artine.
          </div>
        </div>
        <div className="btn-view-details ">
          <Link
            href=""
            aria-label="Read More"
            className="btn btn-outline-theme-colored1 btn-outline"
          >
            Discover More{" "}
          </Link>
        </div>
        <div className="icon">
          <div className="service-icon">
            <i aria-hidden="true" className=" flaticon-hotel-030-weights" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </Container>
    </div> */}
    </div>
  );
};

export default SoundRecording;
