import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./Comming.css";
import { FaHeadphonesAlt, FaPhone } from "react-icons/fa";
import { CgMail } from "react-icons/cg";

const CommingSoon = ({ heading, image }) => {
  return (
    <Container className="text-center  cardss">
      <h2 className=" mx-2 cs-title">Comming soon!</h2>
      <p className="cs-text">
        <h2 className="cs-title">Comming Soon</h2>
        We are currently developing the system for a better experience.
        <br />
        Apologize for the inconvenience and thank you.
      </p>
      <Row className="align-items-center justify-content-center row-comming-soom-padd">
        <Col lg={4} md={4}>
          <img
            src="https://www.anu-sport.com.au/UITemplates/DefaultPublic/images/placeholder.jpg?MaxH=300"
            className="img-fluid"
            alt="Bus Background"
          />
        </Col>

        <Col lg={6} md={6}>
          <div className="bus-containerr">
            <h2 className="bus-containerr-h2">
              For any Enquiry Please contact us
            </h2>
            <div className="bus-container-second">
              <div>
                <FaHeadphonesAlt size={23} style={{ marginRight: "5px" }} />{" "}
                <span className="bus-cont-phone">+91 8084187424</span>
              </div>
              <div>
                <CgMail size={25} style={{ marginRight: "5px" }} />{" "}
                <span className="bus-cont-phone">
                  {" "}
                  music@studiofinaltake.com
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CommingSoon;
