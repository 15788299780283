import React from "react";
import Main from "./Main";
import SectionNine from "../Home/sectionnine/SectionNine";
import "./Project.css";
import ProjectTwo from "./ProjectTwo";
import { Helmet } from "react-helmet";
const MainProject = () => {
  return (
    <div>
      <Helmet>
        <title>
          Best Music Production Company in Delhi | Our Projects | Studio Final
          Take
        </title>
        <meta
          name="description"
          content="Explore Studio Final Take's impressive portfolio showcasing why we're the best music production company in Delhi. Discover our exceptional music production services and how we bring creativity to every project."
        />
        <link rel="canonical" href="https://www.studiofinaltake.com/projects" />
        <meta
          property="og:title"
          content="Best Music Production Company in Delhi | Our Projects | Studio Final Take"
        />
        <meta
          property="og:description"
          content="Explore Studio Final Take's impressive portfolio showcasing why we're the best music production company in Delhi. Discover our exceptional music production services and how we bring creativity to every project."
        />
        <meta
          property="og:url"
          content="https://www.studiofinaltake.com/projects"
        />
        <meta property="og:site_name" content="STUDIO FINAL TAKE" />
        <meta property="og:type" content="Local Business" />
        <meta name="keywords" content="music recording studio in delhi" />
        <meta name="keywords" content="mixing and mastering studio in delhi" />
        <meta name="keywords" content="best music recording studio" />
        <meta name="keywords" content="mastering studio in new delhi" />
        <meta name="keywords" content="recording studio in south west delhi" />
        <meta name="keywords" content="song recording studio in new delhi" />
      </Helmet>
      <Main />
      <SectionNine />
      <ProjectTwo />
    </div>
  );
};

export default MainProject;
