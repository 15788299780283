import React, { useState } from "react";
import "./Hero.css";
import BookingModalForm from "../../navbar/BookingModalForm";
import Booking from "../Footer/Booking";
const Hero = () => {
  const [active, setActive] = useState(false);
  return (
    <div
      id="carouselExample"
      className="carousel slide"
      data-bs-ride="carousel"
      // data-bs-ride="carousel"
      data-bs-interval="4000"
      data-bs-pause="false"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <section
            className="container-fluid NEWbanner"
            style={{
              backgroundImage: "url('/Images/SLIDE_02.jpg')",
            }}
          >
            <div className="noverlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-12 hero-sec-margin">
                  <div className="hero-headinggg">
                    <span>Studio</span> <br />
                    Final Take
                  </div>

                  <p>
                    Find all the best music recording and studios near you and
                    book online with live availability. Book a Studio for music
                    production, recording songs, dubbing & vocals.{" "}
                  </p>
                  <button onClick={() => setActive(!active)}>Book Slot</button>
                  {/* <div className="downarrow">
                    <i className="bi bi-arrow-down-circle" />
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="carousel-item">
          <section
            className="container-fluid NEWbanner"
            style={{
              backgroundImage: "url('/Images/Slider_03.jpg')",
            }}
          >
            {" "}
            <div className="noverlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-12 hero-sec-margin">
                  <div className="hero-headinggg">
                    <span>Studio</span> <br />
                    Final Take
                  </div>{" "}
                  <p>
                    Find all the best music recording and studios near you and
                    book online with live availability. Book a Studio for music
                    production, recording songs, dubbing & vocals.{" "}
                  </p>
                  <button onClick={() => setActive(!active)}>Book Slot</button>
                  {/* <div className="downarrow">
                    <i className="bi bi-arrow-down-circle" />
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="carousel-item">
          <section
            className="container-fluid NEWbanner"
            style={{
              backgroundImage: "url('/Images/SLIDE_01.jpg')",
            }}
          >
            {" "}
            <div className="noverlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-12 hero-sec-margin">
                  <div className="hero-headinggg">
                    <span>Studio</span> <br />
                    Final Take
                  </div>

                  <p>
                    Find all the best music recording and studios near you and
                    book online with live availability. Book a Studio for music
                    production, recording songs, dubbing & vocals.{" "}
                  </p>

                  <button onClick={() => setActive(!active)}>Book Slot</button>
                  {/* <div className="downarrow">
                    <i className="bi bi-arrow-down-circle" />
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {active ? <Booking active={active} setActive={setActive} /> : ""}
      {/* <div className="carousel-indicators-custom">
        <button
          className="carousel-indicator"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide-to={0}
        >
          01
        </button>
        <button
          className="carousel-indicator"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide-to={1}
        >
          02
        </button>
        <button
          className="carousel-indicator"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide-to={2}
        >
          03
        </button>
      </div> */}
    </div>
  );
};

export default Hero;
