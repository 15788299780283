import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Conclusion = () => {
  return (
    <div
      className="conclusion-area-three pb-70"
      style={{ background: "#f5f5f5" }}
    >
      <Container>
        <Row className="align-items-center pt-45">
          <Col md={6} lg={6}>
            <div class="conclusion-img-two">
              <img
                src="/Images/studio-final-take-commitement.png"
                alt="studio final take commitement"
              />
            </div>
          </Col>
          <Col md={6} lg={6}>
            <div className="faq-conte faq-content-bg2">
              {/* <i className="flaticon-left-quote" /> */}
              <div class="section-title">
                <span class="sp-color">Our Commitment to Excellence</span>
                {/* <h2>Our Latest Testimonials and What Our Client Says</h2> */}
              </div>
              <p style={{ marginTop: "10px" }}>
                At Studio Final Take, we are passionate about sound recording
                and dedicated to helping our clients achieve their creative
                vision. We work closely with you throughout the recording
                process, offering guidance and support to ensure the final
                product exceeds your expectations.
              </p>
              <br />
              <br />
              <p>
                We also offer a range of post-production services, including
                mixing and mastering, to polish your recordings and prepare them
                for release. Our goal is to provide a seamless and enjoyable
                experience from start to finish.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Conclusion;
