import React from "react";
import "./Reusable.css";
import { Container, Row, Col } from "react-bootstrap";
const ReusableBanner = ({ banner, headone, headtwo, para }) => {
  return (
    <div className="banner__area" style={{ backgroundImage: `url(${banner})` }}>
      <div className="noverlay"></div>
      <Container>
        <Row>
          <Col xl={12}>
            <div className="banner__area-title">
              <div>
                {headone},<span>{headtwo}</span>
              </div>
              <p className="sound-record-firstp">{para}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReusableBanner;
