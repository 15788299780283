import { Route, Routes, useLocation, useParams } from "react-router-dom";
import "./App.css";
// import Navbar from "./component/navbar/Navbar";
import Home from "./component/Home/Home";
// import Footer from "./component/footer/Footer";
import About from "./component/AboutUs/About";
import Contact from "./component/Contact/Contact";
// import StudioRecording from "./component/StudioRecording/StudioRecording";
import Footer from "./component/Home/Footer/Footer";
import WhatsApp from "./component/whatsapp/Whatsapp";
// import StudioMain from "./component/StudioRecording/StudioMain";
import Studio from "./component/StudioRecording/Studio";
import SoundRecording from "./component/SoundRecording/SoundRecording";
import ImageModal from "./ImageModal";
import MainProject from "./component/Projects/MainProject";
import MusicProduction from "./component/MusicProduction/MusicProduction";
import VoiceOver from "./component/VoiceOver/VoiceOver";
import MixingMastering from "./component/MixingMastering/MixingMastering";
import MusicDistribution from "./component/MusicDistribution/MusicDistribution";
import VideoProduction from "./component/VIdeoProduction/VideoProduction";
import ModelManagement from "./component/ModelManagement/ModelManagement";
import Review from "./component/Review/Review";
import Navbars from "./component/Navbars/Navbar";
import { useEffect } from "react";
import FullQA from "./component/Home/QASection/FullQA";
import CommingSoon from "./CommingSoon";
import Comming from "./Comming";
import MainGallery from "./component/Gallery/MainGallery";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    // Set a cookie with SameSite=Strict
    document.cookie = "myCookie=cookieValue; SameSite=Strict; Secure";
  }, []);
  return (
    <div>
      {/* <Navbar /> */}
      <ImageModal />
      <Navbars />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<MainProject />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/sound-recording-studio" element={<SoundRecording />} />
        <Route path="/music-production-studio" element={<MusicProduction />} />
        <Route path="/voice-over-dubbing-studio" element={<VoiceOver />} />
        <Route path="/mixing-mastering-studio" element={<MixingMastering />} />
        <Route
          path="/music-distribution-company"
          element={<MusicDistribution />}
        />
        <Route path="/video-production-company" element={<VideoProduction />} />
        <Route path="/model-management-company" element={<ModelManagement />} />
        <Route path="/studio-final-take-review" element={<Review />} />
        <Route path="/faq" element={<FullQA />} />
        <Route path="/comming-soon" element={<Comming />} />
        <Route path="/studio-final-take-gallery" element={<MainGallery />} />
      </Routes>
      <Footer />
      <WhatsApp />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
