import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Container } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
// import { CiCalendarDate } from "react-icons/ci";
import {
  FaCalendarAlt,
  FaFacebookF,
  FaHome,
  FaInstagram,
  FaLinkedinIn,
  FaPhone,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { Link } from "react-router-dom";
// import BookingModalForm from "./BookingModalForm";
import { RiMenu3Fill } from "react-icons/ri";
import { FaLocationDot } from "react-icons/fa6";

// import Logo from './logo.png'

const Navbars = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [active, setIsActive] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
    setIsActive(!active);
  };

  const handleScroll = () => {
    setIsSticky(window.scrollY > 40);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
    <div className="" id="home">
      <header
        className={`${
          isSticky ? "container-fluidss" : "container-fluidss"
        } dog`}
      >
        <div className="px-lg-5">
          <div className="row">
            <div className="cheader">
              <Link to="/">
                <img
                  title=""
                  src="/Images/studio-final-take-logo.png"
                  className="img-fluid logo"
                  alt="studio final take logo"
                  srcSet=""
                />
              </Link>

              <div>
                <Link className="" title="Contact Us" to="tel:+918084187424">
                  <FaPhoneAlt
                    className="navbar-fontsss"
                    size={30}
                    color="#d70d57"
                  />
                </Link>
              </div>
              <div>
                <Link
                  to="https://maps.app.goo.gl/x34nZNMF4gfq3gDS6"
                  target="_blank"
                  className="Contact Us location-mapsss phone-number-fonts"
                >
                  <img
                    src="/Images/google-maps.png"
                    alt="studio final take location"
                    title="Location"
                    className="google-map-imggg"
                  />
                  {/* <span className="tooltiptext">Location</span> */}
                  {/* <i class="bi bi-geo-alt"></i> */}
                </Link>
              </div>

              <div className={`navigation ${active ? "active" : ""}`}>
                <ul>
                  <li className="dropdown">
                    <Link
                      to="/"
                      className={`${
                        isSticky ? "active-color-li" : "active-color-li"
                      }`}
                     
                    >
                      <FaHome size={20} className="nav-logo-style" />
                    </Link>
                    {/* <ul className="dropdown-menu">
                      <li>
                        <Link title="Meet Our Team">About the Company</Link>
                      </li>
                      <li>
                        <Link title="Meet Our Team">About the Founder</Link>
                      </li>
                      <li>
                        <Link title="Company Info">Our People</Link>
                      </li>
                      <li>
                        <Link title="Contact Foundations Group of MN">
                          Pitch Deck
                        </Link>
                      </li>
                    </ul> */}
                  </li>
                  <li className="dropdown" onClick={() => setIsActive(false)}>
                    <Link
                      className={`${
                        isSticky ? "active-color-li" : "active-color-li"
                      }`}
                      to="/about"
                     
                    >
                      STUDIO{" "}
                      {/* <MdOutlineDoubleArrow
                        width={20}
                        color="#dc2751"
                        style={{ marginLeft: "-6px", textAlign: "center" }}
                      /> */}
                    </Link>
                    {/* <ul className="dropdown-menu">
                      <li>
                        <Link title="Meet Our Team">About the Company</Link>
                      </li>
                      <li>
                        <Link title="Company Info">About the Founder</Link>
                      </li>
                      <li>
                        <Link title="Contact Foundations Group of MN">
                          Our People
                        </Link>
                      </li>
                      <li>
                        <Link title="Career Opportunities">Pitch Deck</Link>
                      </li>
                    
                    </ul> */}
                  </li>
                  <li className="dropdown">
                    <Link
                      className={`${
                        isSticky ? "active-color-li" : "active-color-li"
                      } service_text_clr`}
                      title="About Foundations Group of MN"
                    >
                      Services
                    </Link>
                    <ul className="dropdown-menu">
                      <li onClick={() => setIsActive(!active)}>
                        <Link
                          to="/sound-recording-studio"
                          title="Sound Recording"
                        >
                          Sound Recording
                        </Link>
                      </li>
                      <li onClick={() => setIsActive(!active)}>
                        <Link
                          title="Music Production"
                          to="/music-production-studio"
                        >
                          Music Production
                        </Link>
                      </li>
                      <li onClick={() => setIsActive(!active)}>
                        <Link
                          title="Voice Over & Dubbing"
                          to="/voice-over-dubbing-studio"
                        >
                          Voice Over & Dubbing
                        </Link>
                      </li>
                      <li onClick={() => setIsActive(!active)}>
                        <Link
                          title="Career Opportunities"
                          to="/mixing-mastering-studio"
                        >
                          Mixing & Mastering
                        </Link>
                      </li>
                      <li onClick={() => setIsActive(!active)}>
                        <Link
                          title="Music Distribution"
                          to="https://fttunes.com/"
                          target="_blank"
                        >
                          Music Distribution
                        </Link>
                      </li>
                      <li onClick={() => setIsActive(!active)}>
                        <Link
                          title="Video Production"
                          to="/video-production-company"
                        >
                          Video Production
                        </Link>
                      </li>
                      <li onClick={() => setIsActive(!active)}>
                        <Link
                          title="Models Management"
                          to="/model-management-company"
                        >
                          Models Management
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li onClick={() => setIsActive(false)}>
                    <Link
                      to="/projects"
                      className={`${
                        isSticky ? "active-color-li" : "active-color-li"
                      }`}
                      title="Contact Us"
                    >
                      Projects
                    </Link>
                  </li>
                  <li onClick={() => setIsActive(false)}>
                    <Link
                      to="/studio-final-take-gallery"
                      className={`${
                        isSticky ? "active-color-li" : "active-color-li"
                      }`}
                      title="Contact Us"
                    >
                      Gallery
                    </Link>
                  </li>
                  <li onClick={() => setIsActive(false)}>
                    <Link
                      to="/contact"
                      className={`${
                        isSticky ? "active-color-li" : "active-color-li"
                      }`}
                      title="Contact Us"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li className="navbar_phone_number phone-number-bttn">
                    <Link
                      className=""
                      title="Contact Us"
                      to="tel:+918084187424"
                    >
                      <FaPhoneAlt className="navbar-fonts" size={20} />
                      <div className="txt phone-number-fonts">
                        +91 8084187424
                      </div>
                    </Link>
                  </li>
                  {/* <li onClick={()=>setIsActive(false)} className="navbar_phone_number">
                    <Link
                      to="/contact"
                      className={`${
                        isSticky ? "active-color-li" : "active-color-li"
                      }`}
                      title="Contact Us"
                    >
                      +91 8084187424
                    </Link>
                  </li> */}
                  <li className="socialss" style={{ marginTop: "10px" }}>
                    <div className="sixstyle__FooterSection-sc-915j0m-4 hLuZHF">
                      <div className="SocialShare__Wrapper-h9wts-0 qJGGM social_profiles">
                        <div className="SocialShare__Item-h9wts-1 fvcUgI social_profile_item">
                          <Link
                            to="https://www.instagram.com/finaltakefilmproduction/"
                            target="_blank"
                            alt="instagram"
                            onClick={() => setIsActive(false)}
                          >
                            <FaInstagram />
                          </Link>
                        </div>
                        <div className="SocialShare__Item-h9wts-1 fvcUgI social_profile_item">
                          <Link
                            to="https://www.x.com/Finaltakeindia"
                            alt="twitter"
                            target="_blank"
                            onClick={() => setIsActive(false)}
                          >
                            <FaTwitter />
                          </Link>
                        </div>
                        <div className="SocialShare__Item-h9wts-1 fvcUgI social_profile_item">
                          <Link
                            to="https://www.facebook.com/finaltakefilmproduction"
                            target="_blank"
                            onClick={() => setIsActive(false)}
                            alt="facebook"
                          >
                            <FaFacebookF />
                          </Link>
                        </div>
                        <div className="SocialShare__Item-h9wts-1 fvcUgI social_profile_item">
                          <Link
                            tp="http://www.linkedin.com/in/finaltakefilmproduction"
                            target="_blank"
                            alt="linkedin"
                            onClick={() => setIsActive(false)}
                          >
                            <FaLinkedinIn />
                          </Link>
                        </div>
                        <div className="SocialShare__Item-h9wts-1 fvcUgI social_profile_item">
                          <Link
                            to="https://www.maps.app.goo.gl/4ftPgrEtrWh5cbDw8"
                            target="_blank"
                            onClick={() => setIsActive(false)}
                          >
                            <FaLocationDot />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className={`location ${active ? "active" : ""}`}>
                <ul>
                  {/* <li className="getStarted" onClick={handleOpenModal}>
                    <Link className="d-flex" title="Contact Us">
                      <FaCalendarAlt className="navbar-fonts" />
                      <div className="txt d-lg-block d-none">Book Slot</div>
                    </Link>
                  </li> */}
                  <li className="getStarted phone-number-bttn">
                    <Link
                      className="d-flex"
                      title="Contact Us"
                      to="tel:+918084187424"
                    >
                      <FaPhoneAlt className="navbar-fonts" size={20} />
                      <div className="txt phone-number-fonts">
                        +91 8084187424
                      </div>
                    </Link>
                  </li>
                  <li className="getStarted location-maps tooltipss">
                    <Link
                      to="https://maps.app.goo.gl/x34nZNMF4gfq3gDS6"
                      target="_blank"
                      className="Contact Us location-maps phone-number-fonts tooltipss"
                    >
                      <img
                        src="/Images/google-maps.png"
                        alt="studio final take location"
                        title="Location"
                        className="google-map-img"
                      />
                      <span className="tooltiptext">Location</span>
                      {/* <i class="bi bi-geo-alt"></i> */}
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className={`menu hamburger ${isOpen ? "open" : ""}`}
                onClick={() => handleMenuClick()}
              >
                <RiMenu3Fill className="ic" size={30} color="#d70d57" />
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <BookingModalForm show={showModal} handleClose={handleCloseModal} /> */}
    </div>
  );
};

export default Navbars;
