import React from "react";

const ProjectTwo = () => {
  return (
    <section className="bg-light">
      <div className="container py-5">
        <h2 className="mb-5 text-center">
          <strong>STUDIO</strong> Final Take Showcase
        </h2>
        <div className="">
          <div className="" id="">
            <div className="">
              <div className="row galvid">
                <div className="col-md-6 galvideo">
                  <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    src="https://www.youtube.com/embed/B7IeqPkBJaw?si=Y2FSND58SqgfhlOe"
                    title="YouTube video player"
                  />
                </div>
                <div className="col-md-6 galvideo">
                  <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    src="https://www.youtube.com/embed/KGcDVRIavS4"
                    title="YouTube video player"
                  />
                </div>

                <div className="col-md-6 galvideo">
                  <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    src="https://www.youtube.com/embed/Db2FOhU2JBs"
                    title="YouTube video player"
                  />
                </div>
                <div className="col-md-6 galvideo">
                  <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    src="https://www.youtube.com/embed/YnEAvBpgRxs"
                    title="YouTube video player"
                  />
                </div>
                <div className="col-md-6 galvideo">
                  <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    src="https://www.youtube.com/embed/iGgzopKbO-E"
                    title="YouTube video player"
                  />
                </div>
                <div className="col-md-6 galvideo">
                  <iframe
                    width="965"
                    height="543"
                    src="https://www.youtube.com/embed/Lmy10bK0x9U"
                    title="Tere Naal Pyar | Ritik Gupta &amp; Ekta shasodia | Cute College Love Story | Official Video Song"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="col-md-6 galvideo">
                  <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    src="https://www.youtube.com/embed/Log6AA17FSU"
                    title="YouTube video player"
                  />
                </div>

                {/* <div className="col-md-6 galvideo">
                  <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    
                    height="480"
                    src="https://www.youtube.com/embed/yYJpquUjrBo"
                    title="#Rinku_Ojha CHHOTI MOTI DIYA ME JAHAN JHALAKE | छोटी मोटी दिया में जहान झलके | DEVI GEET 2022"
                    width="853"
                  />
                </div>
                <div className="col-md-6 galvideo">
                  <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    
                    src="https://www.youtube.com/embed/5ywWBVMBRjY"
                    title="#newbolbumsong Manish Albela Song Harihar Chudia Devghar ke / हरीहर चुड़िआ देवघर के"
                  />
                </div>
                <div className="col-md-6 galvideo">
                  <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    
                    src="https://www.youtube.com/embed/nfoofsnsOFw"
                    title="#bolbumsong सुनs एहो बम Sun yeho Bam / #Chalinder Singh Chandravanshi / new bol bum song 2022"
                  />
                </div> */}
                <div className="col-md-6 galvideo">
                  <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    src="https://www.youtube.com/embed/9Aa3s6Evqwk"
                    title="YouTube video player"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectTwo;
