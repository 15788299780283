import React, { useState } from "react";
import { Accordion, Button, Card, Col, Container, Row } from "react-bootstrap";
import "./QA.css";
import { Link } from "react-router-dom";
const QASection = () => {
  const [active, setActive] = useState(false);
  const [openItemId, setOpenItemId] = useState(null);

  const handleClick = (id) => {
    setOpenItemId(openItemId === id ? null : id);
  };
  const data = [
    {
      head: "Can you briefly describe what your service is?",
      ans: "I need to book flights to apply for a visa or at the airport. Is this what I need?",
    },
    {
      head: "Can you briefly describe what your service is?",
      ans: "I need to book flights to apply for a visa or at the airport. Is this what I need?",
    },
    {
      head: "Can you briefly describe what your service is?",
      ans: "I need to book flights to apply for a visa or at the airport. Is this what I need?",
    },
    {
      head: "Can you briefly describe what your service is?",
      ans: "I need to book flights to apply for a visa or at the airport. Is this what I need?",
    },
    {
      head: "Can you briefly describe what your service is?",
      ans: "I need to book flights to apply for a visa or at the airport. Is this what I need?",
    },
    {
      head: "Can you briefly describe what your service is?",
      ans: "I need to book flights to apply for a visa or at the airport. Is this what I need?",
    },
  ];
  return (
    <section className="QA_qa__OoY9jj">
      <Container>
        <div className="QA_qa__wrapper__qmfPl">
          <h2 className="QA_qa__title__MiQEd">
            RECORDING STUDIO COMMONLY ASKED QUESTIONS
          </h2>
          <Container>
            <Row>
              <Col md={6}>
                <div
                  className="accordion Accordion_accordion__x6X1A"
                  id="accordionExample"
                >
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <h2
                      className="accordion-header AccordionItem_accordion__ZDG94"
                      id="headingOne"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#colla"
                        aria-expanded="false"
                        aria-controls="colla"
                      >
                        <div className="AccordionItem_accordion__text__x0TKp">
                          <h2 className="AccordionItem_accordion__title__ZCjqr">
                            What is your address and location?
                          </h2>
                          <div className="AccordionItem_accordion__icon__A95W6">
                            <img
                              className="AccordionItem_accordion__icon__A95W6"
                              src="/Images/plus.svg"
                              alt="plus"
                            />
                            {/* <img /> */}
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="colla"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                        <b>Studio Final Take,</b> a Premier Music Production and
                        Recording Studio located in <b>A2, 2nd Floor Palam Dabri
                        Road, Mahavir Enclave Dwarka,New Delhi - 110045
                        Dashrathpuri, Delhi.</b>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <h2
                      className="accordion-header AccordionItem_accordion__ZDG94"
                      id="headingOnee"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collaps"
                        aria-expanded="false"
                        aria-controls="collaps"
                      >
                        <div className="AccordionItem_accordion__text__x0TKp">
                          <h2 className="AccordionItem_accordion__title__ZCjqr">
                            How much does it cost to record?
                          </h2>
                          <div className="AccordionItem_accordion__icon__A95W6">
                            <img
                              className="AccordionItem_accordion__icon__A95W6"
                              src="/Images/plus.svg"
                              alt="plus"
                            />
                            {/* <img /> */}
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collaps"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOnee"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                       At   <b>Studio Final Take,</b> the cost to record depends on the
                        specifics of the song however <b>our price starts from
                        14999.</b> There are so many factors. Are you well prepared/
                        rehearsed? How many instruments / tracks will be
                        involved in the song? We would be happy to meet with you
                        to discuss your project goals to give you an accurate
                        estimate, but there are literally thousands of correct
                        answers to this question. <b>For detailed pricing, please
                        contact us via phone at{" "}
                        <Link to="tel:+918084187424">8084187424</Link> .</b>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <h2
                      className="accordion-header AccordionItem_accordion__ZDG94"
                      id="headingOn"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseO"
                        aria-expanded="false"
                        aria-controls="collapseO"
                      >
                        <div className="AccordionItem_accordion__text__x0TKp">
                          <h2 className="AccordionItem_accordion__title__ZCjqr">
                            Who do i contact about booking and billing?
                          </h2>
                          <div className="AccordionItem_accordion__icon__A95W6">
                            <img
                              className="AccordionItem_accordion__icon__A95W6"
                              src="/Images/plus.svg"
                              alt="plus"
                            />
                            {/* <img /> */}
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseO"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOn"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                        For <b>booking and billing,</b> contact STUDIO FINAL TAKE at
                        <b>  +91 8084187424 or e-mail at music@studiofinaltake.com</b>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <h2
                      className="accordion-header AccordionItem_accordion__ZDG94"
                      id="headingOneee"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneee"
                        aria-expanded="false"
                        aria-controls="collapseOneee"
                      >
                        <div className="AccordionItem_accordion__text__x0TKp">
                          <h2 className="AccordionItem_accordion__title__ZCjqr">
                            What are your working hours?
                          </h2>
                          <div className="AccordionItem_accordion__icon__A95W6">
                            <img
                              className="AccordionItem_accordion__icon__A95W6"
                              src="/Images/plus.svg"
                              alt="plus"
                            />
                            {/* <img /> */}
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseOneee"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOneee"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                        Studio Final Take is <b>open for 7 days, from 10 AM to 8
                        PM.</b>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <h2
                      className="accordion-header AccordionItem_accordion__ZDG94"
                      id="headingO"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOnee"
                        aria-expanded="false"
                        aria-controls="collapseOnee"
                      >
                        <div className="AccordionItem_accordion__text__x0TKp">
                          <h2 className="AccordionItem_accordion__title__ZCjqr">
                            I want to record but need some guidance with my
                            project. Can you help?
                          </h2>
                          <div className="AccordionItem_accordion__icon__A95W6">
                            <img
                              className="AccordionItem_accordion__icon__A95W6"
                              src="/Images/plus.svg"
                              alt="plus"
                            />
                            {/* <img /> */}
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseOnee"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingO"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                      <b> Absolutely! At Studio Final Take,</b> our experienced team
                        offers comprehensive guidance for your recording
                        project. From pre-production to final mix, we'll assist
                        with planning, technical support and creative input to
                        ensure your project achieves the highest quality and
                        meets your artistic goals.
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <h2
                      className="accordion-header AccordionItem_accordion__ZDG94"
                      id="headingO"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        <div className="AccordionItem_accordion__text__x0TKp">
                          <h2 className="AccordionItem_accordion__title__ZCjqr">
                            What if my pitch is a little Off? Can you fix it?
                          </h2>
                          <div className="AccordionItem_accordion__icon__A95W6">
                            <img
                              className="AccordionItem_accordion__icon__A95W6"
                              src="/Images/plus.svg"
                              alt="plus"
                            />
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingO"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                       <b> Yes, absolutely! At Studio Final Take,</b> we offer
                        comprehensive music editing services, including pitch
                        correction. Our skilled engineers can adjust your pitch
                        to ensure it matches the desired sound and quality.
                        We’re here to refine your music and bring out the best
                        in your recordings.
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <h2
                      className="accordion-header AccordionItem_accordion__ZDG94"
                      id="headingO"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        <div className="AccordionItem_accordion__text__x0TKp">
                          <h2 className="AccordionItem_accordion__title__ZCjqr">
                            Do you do mastering?
                          </h2>
                          <div className="AccordionItem_accordion__icon__A95W6">
                            <img
                              className="AccordionItem_accordion__icon__A95W6"
                              src="/Images/plus.svg"
                              alt="plus"
                            />
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingO"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                       <b> Yes, absolutely! At Studio Final Take,</b> we offer
                        comprehensive Music Production Services, including
                        mastering. Our team of experts ensures that your tracks
                        are polished and professionally finished to meet
                        industry standards. Whether it's mixing or mastering,
                        we've got you covered for all your music production
                        needs.
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <h2
                      className="accordion-header AccordionItem_accordion__ZDG94"
                      id="headingO"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        <div className="AccordionItem_accordion__text__x0TKp">
                          <h2 className="AccordionItem_accordion__title__ZCjqr">
                            Can you release my song on all major audio platforms
                            worldwide?
                          </h2>
                          <div className="AccordionItem_accordion__icon__A95W6">
                            <img
                              className="AccordionItem_accordion__icon__A95W6"
                              src="/Images/plus.svg"
                              alt="plus"
                            />
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseTen"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingO"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                     <b>   Yes, we offer global song distribution at Studio Final
                     Take.</b> We can release your music across all major
                        platforms,<b> including Spotify, Apple Music, Amazon Music
                        and more.</b> Our team handles the entire process, ensuring
                        your song reaches a worldwide audience efficiently and
                        effectively.
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div
                  className="accordion Accordion_accordion__x6X1A"
                  id="accordionExample"
                >
 <div
                      className="accordion-item"
                      style={{ background: "none", border: "none" }}
                    >
                      <h2
                        className="accordion-header AccordionItem_accordion__ZDG94"
                        id="headingO"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          <div className="AccordionItem_accordion__text__x0TKp">
                            <h2 className="AccordionItem_accordion__title__ZCjqr">
                              Your studio is beautiful! Can I take a tour?
                            </h2>
                            <div className="AccordionItem_accordion__icon__A95W6">
                              <img
                                className="AccordionItem_accordion__icon__A95W6"
                                src="/Images/plus.svg"
                                alt="plus"
                              />
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingO"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                         <b> Yes, of course! Studio Final Take welcomes</b> you to tour
                          our <b>studio during business hours.</b> You can book an
                          appointment to ensure availability.
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      style={{ background: "none", border: "none" }}
                    >
                      <h2
                        className="accordion-header AccordionItem_accordion__ZDG94"
                        id="headingO"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          <div className="AccordionItem_accordion__text__x0TKp">
                            <h2 className="AccordionItem_accordion__title__ZCjqr">
                              I want to record a song. Where do I start?
                            </h2>
                            <div className="AccordionItem_accordion__icon__A95W6">
                              <img
                                className="AccordionItem_accordion__icon__A95W6"
                                src="/Images/plus.svg"
                                alt="plus"
                              />
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingO"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                          To start <b>recording a song at Studio Final Take,</b> you
                          can contact us <b>via phone at 8084187424 or fill our
                          contact form on our website</b> our team will surely
                          connect with you.
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      style={{ background: "none", border: "none" }}
                    >
                      <h2
                        className="accordion-header AccordionItem_accordion__ZDG94"
                        id="headingO"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEleven"
                          aria-expanded="false"
                          aria-controls="collapseEleven"
                        >
                          <div className="AccordionItem_accordion__text__x0TKp">
                            <h2 className="AccordionItem_accordion__title__ZCjqr">
                              What type of equipment and gear do you have?
                            </h2>
                            <div className="AccordionItem_accordion__icon__A95W6">
                              <img
                                className="AccordionItem_accordion__icon__A95W6"
                                src="/Images/plus.svg"
                                alt="plus"
                              />
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseEleven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingO"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                          Studio Final Take uses the <b>latest equipment for all
                          our recording and production needs.</b> For more detailed
                          information, please check our website.
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      style={{ background: "none", border: "none" }}
                    >
                      <h2
                        className="accordion-header AccordionItem_accordion__ZDG94"
                        id="headingO"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwelve"
                          aria-expanded="false"
                          aria-controls="collapseTwelve"
                        >
                          <div className="AccordionItem_accordion__text__x0TKp">
                            <h2 className="AccordionItem_accordion__title__ZCjqr">
                              Do you have any specials or packages?
                            </h2>
                            <div className="AccordionItem_accordion__icon__A95W6">
                              <img
                                className="AccordionItem_accordion__icon__A95W6"
                                src="/Images/plus.svg"
                                alt="plus"
                              />
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseTwelve"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingO"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                         <b> Studio Final Take offers various discounts and
                         packages.</b> For more details, please fill out our
                          contact form or call us.
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      style={{ background: "none", border: "none" }}
                    >
                      <h2
                        className="accordion-header AccordionItem_accordion__ZDG94"
                        id="headingO"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThirteen"
                          aria-expanded="false"
                          aria-controls="collapseThirteen"
                        >
                          <div className="AccordionItem_accordion__text__x0TKp">
                            <h2 className="AccordionItem_accordion__title__ZCjqr">
                              I need a Hip Hop song to record my vocals to. Do
                              you have anyone who creates beats?
                            </h2>
                            <div className="AccordionItem_accordion__icon__A95W6">
                              <img
                                className="AccordionItem_accordion__icon__A95W6"
                                src="/Images/plus.svg"
                                alt="plus"
                              />
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseThirteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingO"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                          <b>Absolutely! At Studio Final Take, </b>we have a team of
                          talented beat creators who specialize in Hip Hop.
                          Whether you're looking for a custom beat or a pre-made
                          track, <b>our professionals can create the perfect
                          background for your vocals.</b>
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      style={{ background: "none", border: "none" }}
                    >
                      <h2
                        className="accordion-header AccordionItem_accordion__ZDG94"
                        id="headingO"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseForteen"
                          aria-expanded="false"
                          aria-controls="collapseForteen"
                        >
                          <div className="AccordionItem_accordion__text__x0TKp">
                            <h2 className="AccordionItem_accordion__title__ZCjqr">
                              I am a singer & I do not have any songs. Do you
                              have someone who could write both music and lyrics
                              for me?
                            </h2>
                            <div className="AccordionItem_accordion__icon__A95W6">
                              <img
                                className="AccordionItem_accordion__icon__A95W6"
                                src="/Images/plus.svg"
                                alt="plus"
                              />
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseForteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingO"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                          <b>Yes, at Studio Final Take,</b> we have a team of
                          experienced songwriters who specialize in crafting
                          both music and lyrics tailored to your unique style
                          and vision. We work closely with you to create
                          original compositions that resonate with your artistic
                          identity, ensuring every piece is a perfect fit for
                          your voice and brand.
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      style={{ background: "none", border: "none" }}
                    >
                      <h2
                        className="accordion-header AccordionItem_accordion__ZDG94"
                        id="headingO"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFifteen"
                          aria-expanded="false"
                          aria-controls="collapseFifteen"
                        >
                          <div className="AccordionItem_accordion__text__x0TKp">
                            <h2 className="AccordionItem_accordion__title__ZCjqr">
                              Can i bring in my own engineer?
                            </h2>
                            <div className="AccordionItem_accordion__icon__A95W6">
                              <img
                                className="AccordionItem_accordion__icon__A95W6"
                                src="/Images/plus.svg"
                                alt="plus"
                              />
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseFifteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingO"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AccordionItem_accordion__content__Md4k6">
                         <b> Yes, you can bring your own engineer to Studio Final
                         Take.</b> However, they should be well-versed in all
                          aspects of Music Production to ensure a smooth and
                          efficient process. Our studio is equipped to <b>support a
                          range of professional needs.</b>
                        </div>
                      </div>
                    </div>



                </div>
              </Col>
            </Row>

          
          </Container>
          
        </div>
      </Container>
    </section>
  );
};

export default QASection;
