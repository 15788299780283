import React from "react";
import "./SectionNine.css";
import { Col, Container, Row } from "react-bootstrap";

// import Mic from "./micro.jpg";
// import Speaker from "./speaker.jpg";

const SectionNine = () => {
  return (
    <div
      className="scrollbar-webkit"
      style={{
        paddingTop: "50px",
        width: "100%",
        display: "flex",
        overflow: "hidden",
      }}
    >
      <div className="wpb_single_imagesss wpb_content_element vc_align_left  wpb_animate_when_almost_visible wpb_slideInLeft slideInLeft  ltx-records-left wpb_start_animation animated">
        <figure className="wpb_wrapper vc_figure">
          <div className="vc_single_image-wrapper mic-width-low  vc_box_border_grey">
            <img
              width={383}
              height={600}
              src="/Images/rod-nt2-a-mic.jpg"
              className="vc_single_image-img attachment-full"
              alt="rod nt2 a mic"
              // srcSet="http://recond.like-themes.com/wp-content/uploads/2019/07/tracks-left.png 383w, http://recond.like-themes.com/wp-content/uploads/2019/07/tracks-left-192x300.png 192w, http://recond.like-themes.com/wp-content/uploads/2019/07/tracks-left-360x564.png 360w, http://recond.like-themes.com/wp-content/uploads/2019/07/tracks-left-351x550.png 351w"
              // sizes="(max-width: 383px) 100vw , 383px"
            />
          </div>
        </figure>
      </div>
      <div className="section-nine-main" style={{ maxWidth: "770px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5
            className="subheader sheading ltx-sr-id-like_sc_header_4461105861476444968 ltx-sr ltx-sr-effect-fade_in ltx-sr-el-block ltx-sr-delay-100 ltx-sr-duration-300 ltx-sr-sequences-50"
            data-sr-id={28}
            style={{
              visibility: "visible",
              transform: "scale(1)",
              opacity: 1,
              transition: "all 0.3s ease 0s",
            }}
          >
            Our portfolio
          </h5>
          <h3
            className="header mheading ltx-sr-id-like_sc_header_4461105861700691694 ltx-sr ltx-sr-effect-fade_in ltx-sr-el-block ltx-sr-delay-0 ltx-sr-duration-1000 ltx-sr-sequences-0"
            data-sr-id={29}
            style={{
              visibility: "visible",
              transform: "scale(1)",
              opacity: 1,
              transition: "all 0.3s ease 0s",
            }}
          >
            Recent Records
          </h3>
          <p style={{ textAlign: "center" }} className="section-nine-para">
            Explore our latest tracks, crafted with passion and creativity. We
            hope our music brings joy and resonates with you.
          </p>
        </div>
        <div>
          <Container className="container-heightscroll">
            <Row
              style={{
                borderBottom: "1px solid #eee",
                borderTop: "1px solid #eee",
                padding: "5px 0px",
              }}
            >
              <Col
                lg={6}
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                Karwaan
              </Col>

              <Col xs={6} lg={6}>
                <audio controls style={{ width: "97%" }}>
                  <source src="/Songs/Karwaan.mp3" type="audio/mp3" />
                </audio>
              </Col>
            </Row>
            <Row
              style={{
                borderBottom: "1px solid #eee",
                borderTop: "1px solid #eee",
                padding: "5px 0px",
              }}
            >
              <Col
                lg={6}
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                Fareb
              </Col>

              <Col xs={6} lg={6}>
                <audio controls style={{ width: "97%" }}>
                  <source src="/Songs/FAREB.mp3" type="audio/mp3" />
                </audio>
              </Col>
            </Row>
            <Row
              style={{
                borderBottom: "1px solid #eee",
                borderTop: "1px solid #eee",
                padding: "5px 0px",
              }}
            >
              <Col
                lg={6}
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                Banke Bihari
              </Col>

              <Col xs={6} lg={6}>
                <audio controls style={{ width: "97%" }}>
                  <source src="/Songs/BankeBihari.mp3" type="audio/mp3" />
                </audio>
              </Col>
            </Row>
            <Row
              style={{
                borderBottom: "1px solid #eee",
                borderTop: "1px solid #eee",
                padding: "5px 0px",
              }}
            >
              <Col
                lg={6}
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                Dard Dena Ya Gum
              </Col>

              <Col xs={6} lg={6}>
                <audio controls style={{ width: "97%" }}>
                  <source src="/Songs/DardDenaYaGum.mp3" type="audio/mp3" />
                </audio>
              </Col>
            </Row>
            <Row
              style={{
                borderBottom: "1px solid #eee",
                borderTop: "1px solid #eee",
                padding: "5px 0px",
              }}
            >
              <Col
                lg={6}
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                Dard-E-Dil
              </Col>

              <Col xs={6} lg={6}>
                <audio controls style={{ width: "97%" }}>
                  <source src="/Songs/DardeEHal.mp3" type="audio/mp3" />
                </audio>
              </Col>
            </Row>
            <Row
              style={{
                borderBottom: "1px solid #eee",
                borderTop: "1px solid #eee",
                padding: "5px 0px",
              }}
            >
              <Col
                lg={6}
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                Gabru Ki Maan
              </Col>

              <Col xs={6} lg={6}>
                <audio controls style={{ width: "97%" }}>
                  <source src="/Songs/GabruKiMaan.mp3" type="audio/mp3" />
                </audio>
              </Col>
            </Row>
            <Row
              style={{
                borderBottom: "1px solid #eee",
                borderTop: "1px solid #eee",
                padding: "5px 0px",
              }}
            >
              <Col
                lg={6}
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                Mere Shyam Shaware
              </Col>

              <Col xs={6} lg={6}>
                <audio controls style={{ width: "97%" }}>
                  <source src="/Songs/MereShyamShaware.mp3" type="audio/mpeg" />
                </audio>
              </Col>
            </Row>
            <Row
              style={{
                borderBottom: "1px solid #eee",
                borderTop: "1px solid #eee",
                padding: "5px 0px",
              }}
            >
              <Col
                lg={6}
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                Lungi Pe Dungi Nahi
              </Col>

              <Col xs={6} lg={6}>
                <audio controls style={{ width: "97%" }}>
                  <source src="/Songs/LungiPeDungiNahi.mp3" type="audio/mpeg" />
                </audio>
              </Col>
            </Row>{" "}
            <Row
              style={{
                borderBottom: "1px solid #eee",
                borderTop: "1px solid #eee",
                padding: "5px 0px",
              }}
            >
              <Col
                lg={6}
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                Mohan Bajake Basuriya
              </Col>

              <Col xs={6} lg={6}>
                <audio controls style={{ width: "97%" }}>
                  <source
                    src="/Songs/MohanBajakeBasuriya.mp3"
                    type="audio/mpeg"
                  />
                </audio>
              </Col>
            </Row>{" "}
            <Row
              style={{
                borderBottom: "1px solid #eee",
                borderTop: "1px solid #eee",
                padding: "5px 0px",
              }}
            >
              <Col
                lg={6}
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                Ritik Qawaali
              </Col>

              <Col xs={6} lg={6}>
                <audio controls style={{ width: "97%" }}>
                  <source src="/Songs/RitikQawaali.mp3" type="audio/mpeg" />
                </audio>
              </Col>
            </Row>
            <Row
              style={{
                borderBottom: "1px solid #eee",
                borderTop: "1px solid #eee",
                padding: "5px 0px",
              }}
            >
              <Col
                lg={6}
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                Tu Meri Zindagi
              </Col>

              <Col xs={6} lg={6}>
                <audio controls style={{ width: "97%" }}>
                  <source src="/Songs/TuMeriZindagi.mp3" type="audio/mpeg" />
                </audio>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="wpb_single_imagesss  mic-width-right  wpb_content_element vc_align_right  wpb_animate_when_almost_visible wpb_slideInRight slideInRight  ltx-records-right wpb_start_animation animated">
        <figure className="wpb_wrapper vc_figure">
          <div className="vc_single_image-wrapper vc_box_border_grey">
            <img
              width={369}
              height={600}
              style={{ overflow: "hidden" }}
              src="/Images/shure-microphone-studio.jpg"
              
              className="vc_single_image-img attachment-full"
              alt="shure microphone studio"
            />
          </div>
        </figure>
      </div>
    </div>
  );
};

export default SectionNine;
