import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaAngleRight, FaStar } from "react-icons/fa";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import Quotes from "./asdf.png";
import { Link } from "react-router-dom";
import "./Review.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import CallToAction from "../Reusable/CallToAction";
import Arrow from "./Arrow";

const Review = () => {
  return (
    <div>
      <div>
        <div
          className="about-sec-one-img"
          style={{ background: "url(/Images/Guitars.jpg)" }}
        >
          <div className="overlay overlay-bold overlay-about"></div>
          <div className="about-sec-one-main">
            <h1 className="about-headone">Studio final take Review</h1>
            <h6 className="about-sex-headsix">
              <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                {" "}
                Home
              </Link>{" "}
              <FaAngleRight /> review
            </h6>
          </div>
        </div>
      </div>

      <div className="review-container">
        <div className="yutu-video-cont">
          <h1 className="sheading">
            Happy Artists{" "}
            <img
              src="/Images/happy.png"
              alt=""
              className="review_image_section"
            />
          </h1>

          <p className="mheading" style={{ textAlign: "center" }}>
            {" "}
            Welcome to Studio Final Take, where creativity thrives through
            captivating videos, evocative music compositions, and stunning
            visual art. Our collaborative space invites artists, musicians, and
            creators to explore new realms of expression and connection, shaping
            tomorrow’s artistic landscape with passion and precision.
          </p>
        </div>
        <Container>
          <Row>
            <Col xs={12} sm={6} md={4}>
              <div className="cl_lst_box">
                <div className="client_icon hidden-xs">
                  <img
                    src={Quotes}
                    loading="lazy"
                    alt="Client Quote"
                    title="Client Quote"
                    width={64}
                    height={50}
                  />
                </div>
                <div className="clie_r_s">
                  <p className="clie_cnt">
                    "Best place for all the music professionals and actors too.
                    You will like a family here, Excellent environment. Rates
                    are also reasonable. Loved the quality of work. Thanks Final
                    Take team for helping me."
                  </p>
                  <div className="mt25 bb1" />
                  <div className="text-center">
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaRegStarHalfStroke color="#fde16d" size={15} />
                  </div>
                  <p className="cli_na text-center">Manav Singh</p>
                </div>
                <p className="clearfix" />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div className="cl_lst_box">
                <div className="client_icon hidden-xs">
                  <img
                    src={Quotes}
                    loading="lazy"
                    alt="Client Quote"
                    title="Client Quote"
                    width={64}
                    height={50}
                  />
                </div>
                <div className="clie_r_s">
                  <p className="clie_cnt">
                    Nice platform of new and talented stars, actor, Singer
                    Lyricist & music composer. Whoever has water as his
                    destination he will definitely come Because STUDIO FINAL
                    TAKE is no longer away from anyone...
                  </p>
                  <div className="mt25 bb1" />
                  <div className="text-center">
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaRegStarHalfStroke color="#fde16d" size={15} />
                  </div>
                  <p className="cli_na text-center">Vishnu Dev</p>
                </div>
                <p className="clearfix" />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div className="cl_lst_box">
                <div className="client_icon hidden-xs">
                  <img
                    src={Quotes}
                    loading="lazy"
                    alt="Client Quote"
                    title="Client Quote"
                    width={64}
                    height={50}
                  />
                </div>
                <div className="clie_r_s">
                  <p className="clie_cnt">
                    "Mai Dharmanand Saraswati singer and writer final take
                    studio best recording studio delhi I love you final take
                    studio and love you all teams final take production"
                  </p>
                  <div className="mt25 bb1" />
                  <div className="text-center">
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaRegStarHalfStroke color="#fde16d" size={15} />
                  </div>
                  <p className="cli_na text-center">Saraswati Swansargam</p>
                </div>
                <p className="clearfix" />
              </div>
            </Col>
          </Row>
          <Row className="review_sec_row">
            <Col xs={12} sm={6} md={4}>
              <div className="cl_lst_box">
                <div className="client_icon hidden-xs">
                  <img
                    src={Quotes}
                    loading="lazy"
                    alt="Client Quote"
                    title="Client Quote"
                    width={64}
                    height={50}
                  />
                </div>
                <div className="clie_r_s">
                  <p className="clie_cnt">
                    "मैं ऋतिक गुप्ता फाइनल टेक स्टूडियो के साथ लगभग एक डेढ़ साल
                    से जुड़ा हुआ हु, यहाँ काम बहुत ही उन्दा होता हैं अगर आपको
                    मेरा गाना सुनना हैं तो आप मेरे चैनल पर सुन सकते हैं. यूट्यूब
                    में सर्च करे तेरे नाल ऋतिक गुप्ता एक बार स्टूडियो फाइनल टेक
                    को जरूर विजिट करना चाहिए यहाँ के लोग भी बहुत अच्छे एंड
                    सुप्पोर्टिव है."
                  </p>
                  <div className="mt25 bb1" />
                  <div className="text-center">
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaRegStarHalfStroke color="#fde16d" size={15} />
                  </div>
                  <p className="cli_na text-center">Singer Ritik Gupta</p>
                </div>
                <p className="clearfix" />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div className="cl_lst_box">
                <div className="client_icon hidden-xs">
                  <img
                    src={Quotes}
                    loading="lazy"
                    alt="Client Quote"
                    title="Client Quote"
                    width={64}
                    height={50}
                  />
                </div>
                <div className="clie_r_s">
                  <p className="clie_cnt">
                    "Studio final take production is a very good recording
                    studio. There are many good people here"
                  </p>
                  <div className="mt25 bb1" />
                  <div className="text-center">
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaRegStarHalfStroke color="#fde16d" size={15} />
                  </div>
                  <p className="cli_na text-center">Raj Verma</p>
                </div>
                <p className="clearfix" />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div className="cl_lst_box">
                <div className="client_icon hidden-xs">
                  <img
                    src={Quotes}
                    loading="lazy"
                    alt="Client Quote"
                    title="Client Quote"
                    width={64}
                    height={50}
                  />
                </div>
                <div className="clie_r_s">
                  <p className="clie_cnt">
                    "STUDIO FINAL TAKE PRODUCTION VERY BIG PRODUCTION HOUSE
                    DELHI YAHA LOG BHI UTNI HI ACHHE HAI LOVE YOU FINAL TAKE
                    TEAM"
                  </p>
                  <div className="mt25 bb1" />
                  <div className="text-center">
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaRegStarHalfStroke color="#fde16d" size={15} />
                  </div>
                  <p className="cli_na text-center">Anil Sharma</p>
                </div>
                <p className="clearfix" />
              </div>
            </Col>
          </Row>
          <Row className="review_third_row">
            <Col xs={12} sm={6} md={4}>
              <div className="cl_lst_box">
                <div className="client_icon hidden-xs">
                  <img
                    src={Quotes}
                    loading="lazy"
                    alt="Client Quote"
                    title="Client Quote"
                    width={64}
                    height={50}
                  />
                </div>
                <div className="clie_r_s">
                  <p className="clie_cnt">
                    "Very good studio. I liked the mixing of my song a lot."
                  </p>
                  <div className="mt25 bb1" />
                  <div className="text-center">
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaRegStarHalfStroke color="#fde16d" size={15} />
                  </div>
                  <p className="cli_na text-center">Roshan Thapa</p>
                </div>
                <p className="clearfix" />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div className="cl_lst_box">
                <div className="client_icon hidden-xs">
                  <img
                    src={Quotes}
                    loading="lazy"
                    alt="Client Quote"
                    title="Client Quote"
                    width={64}
                    height={50}
                  />
                </div>
                <div className="clie_r_s">
                  <p className="clie_cnt">
                    "It's great studio and management are very technical sound"
                  </p>
                  <div className="mt25 bb1" />
                  <div className="text-center">
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaRegStarHalfStroke color="#fde16d" size={15} />
                  </div>
                  <p className="cli_na text-center">Satya Yadav</p>
                </div>
                <p className="clearfix" />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div className="cl_lst_box">
                <div className="client_icon hidden-xs">
                  <img
                    src={Quotes}
                    loading="lazy"
                    alt="Client Quote"
                    title="Client Quote"
                    width={64}
                    height={50}
                  />
                </div>
                <div className="clie_r_s">
                  <p className="clie_cnt">
                    "best recording studio final take gread person all team yaha
                    ke log bahut achhe hai or kam to mat puchhiy mera lagbhag 10
                    song record huaa hai best work final take production"
                  </p>
                  <div className="mt25 bb1" />
                  <div className="text-center">
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaStar color="#fde16d" size={15} />
                    <FaRegStarHalfStroke color="#fde16d" size={15} />
                  </div>
                  <p className="cli_na text-center">Arjun kashyap</p>
                </div>
                <p className="clearfix" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <div className="review-container yutu-video-cont">
          {" "}
          <h1 className="sheading">
            Happy Artists in the Video
            <img
              src="/Images/happy.png"
              alt=""
              className="review_image_section"
              style={{ marginLeft: "10px" }}
            />
          </h1>
        </div>{" "}
        <div className="slider-video_content" style={{ position: "relative" }}>
          <Container>
            <Swiper
              // slidesPerView={3}
              spaceBetween={30}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              navigation={true}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  // spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              // pagination={{
              //   clickable: true,
              // }}
              modules={[Navigation]}
              className="mySwiper"
            >
              <SwiperSlide style={{ width: "350px" }}>
                <div className="card testicard">
                  <div className="cardvideo">
                    <iframe
                      width="200"
                      height="677"
                      src="https://www.youtube.com/embed/UagqsKoPViQ"
                      title="Happy Artist at Studio Final Take Delhi"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="cardft">
                    <h3>Mrs. Navta Gupta</h3>
                    <small>Delhi, India</small>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ width: "350px" }}>
                <div className="card testicard">
                  <div className="cardvideo">
                    <iframe
                      width="381"
                      height="677"
                      src="https://www.youtube.com/embed/qGHhhC5VUp4"
                      title="Singer Ritik Gupta | Studio Final Take Review "
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="cardft">
                    <h3>Singer Ritik Gupta</h3>
                    <small>Delhi, India</small>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ width: "350px" }}>
                <div className="card testicard">
                  <div className="cardvideo">
                    <iframe
                      width="381"
                      height="677"
                      src="https://www.youtube.com/embed/MxSQR_h-ZO8"
                      title="Actress Madhu | Review Video Final Take Production"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="cardft">
                    <h3>Actress Madhu Malhotra</h3>
                    <small>Delhi, India</small>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ width: "350px" }}>
                <div className="card testicard">
                  <div className="cardvideo">
                    <iframe
                      width="381"
                      height="677"
                      src="https://www.youtube.com/embed/cOI_1c2FIaM"
                      title="Singer &amp; Actress Rajeshwari Singh | Review  Studio Final Take #filmproductionhouse #beststudio"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="cardft">
                    <h3>Mrs. Rajeshwari Singh</h3>
                    <small>Delhi, India</small>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ width: "350px" }}>
                <div className="card testicard">
                  <div className="cardvideo">
                    <iframe
                      width="381"
                      height="677"
                      src="https://www.youtube.com/embed/UOHNkYCtaCU"
                      title="Singer &amp; Actor Mickel Amit | Review - Studio Final Take  #studio #filmproductionhouse #beststudio"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="cardft">
                    <h3>Mr. Mickel Amit</h3>
                    <small>Delhi, India</small>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ width: "350px" }}>
                <div className="card testicard">
                  <div className="cardvideo">
                    <iframe
                      width="381"
                      height="677"
                      src="https://www.youtube.com/embed/MmSFM0tXUXg"
                      title="STUDIO FINAL TAKE FEEDBACK REVIEW, SINGER AND MUSIC TEACHER BABLU PATHAK FEEDBACK"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="cardft">
                    <h3>Mr. Bablu Pathak</h3>
                    <small>Delhi, India</small>
                  </div>
                </div>
              </SwiperSlide>
              {/* <div>
                <Arrow/>
              </div> */}
            </Swiper>
          </Container>
        </div>
      </div>
      <CallToAction />
    </div>
  );
};

export default Review;
