import React from "react";
import "./SectionThird.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
const SectionThird = () => {
  return (
    <section className="bannerDistribution">
      <video autoPlay loop width="100%">
        <source
          src="https://www.finaltakeproduction.com/public/frontend/assets/images/sell-your-music.mp4"
          type="video/mp4"
          alt="sell your music"
        />
        Your browser does not support HTML video.
      </video>
      <div className="videoContent">
        <div>
          <h2>
            <b>Sell Your Music Worldwide</b>
          </h2>
          <div className="storeLogogs">
            <img
              alt="youtube"
              src="https://www.finaltakeproduction.com/public/frontend/assets/images/youtube.svg"
            />
            <img
              alt="jiosaavn"
              src="https://www.finaltakeproduction.com/public/frontend/assets/images/jiosaavn.svg"
            />
            <img
              alt="beatport"
              src="https://www.finaltakeproduction.com/public/frontend/assets/images/beatport.svg"
            />
            <img
              alt="spotify"
              src="https://www.finaltakeproduction.com/public/frontend/assets/images/spotify.svg"
            />
            <img
              alt="apple"
              src="https://www.finaltakeproduction.com/public/frontend/assets/images/apple.svg"
            />
            <img
              alt="hungama"
              src="https://www.finaltakeproduction.com/public/frontend/assets/images/hungama.svg"
            />
          </div>
          <Link className="btn-style" to="https://www.fttunes.com/" target="_blank">
            SIGN UP NOW
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SectionThird;
