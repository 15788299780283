import React from "react";
import ReusableBanner from "../Reusable/ReusableBanner";
import CommingSoon from "../../CommingSoon";
import WhyStudio from "../Reusable/WhyStudio";
import ServiceSection from "../Reusable/ServiceSection";
import WhyUs from "../Reusable/WhyUs";
import CallToAction from "../Reusable/CallToAction";
import { Helmet } from "react-helmet";

const MixingMastering = () => {
  const services = [
    {
      title: "Consultation",
      alt: "consultation",
      image: "/Images/mixing-mastering/consultation.png",
      description:
        "We begin with a detailed consultation to understand your vision and requirements about the track. This helps us align our services with your goals and ensures that we deliver the best possible results.",
    },
    {
      title: "Mixing",
      alt: "mixing",
      image: "/Images/mixing-mastering/mixing.png",
      description:
        "Our mixing process involves meticulous attention to detail. We balance levels, adjust frequencies and apply effects to enhance the overall sound. We ensure that each element of your track shines through, creating a harmonious and impactful mix.",
    },
    {
      title: "Mastering",
      alt: "mastering",
      image: "/Images/mixing-mastering/mastering.png",
      description:
        "In the mastering stage, we fine-tune the mix to achieve a polished and professional sound. We optimize the track for various playback systems, ensuring consistency and clarity across all platforms. Our mastering process includes EQ, compression, limiting and stereo enhancement, among other techniques.",
    },
    {
      title: "Review and Revisions",
      alt: "review and revisions",
      image: "/Images/mixing-mastering/review-and-revisions.png",
      description:
        "We value your feedback and offer multiple rounds of revisions to ensure you are completely satisfied with the final product. We are dedicated to making your music sound its best.",
    },
    {
      title: "Delivery",
      alt: "delivery",
      image: "/Images/mixing-mastering/delivery.png",
      description:
        "Once the mastering is complete, we deliver the final files in your preferred format, ready for distribution and release.",
    },
  ];

  const points = [
    {
      title: "Expertise and Experience",
      description:
        "Our team of seasoned audio engineers possesses extensive experience in the industry, having worked with a diverse range of genres and artists.",
    },
    {
      title: "State-of-the-Art Equipment",
      description:
        "At Studio Final Take, we believe that great music deserves the best tools. Our studio is equipped with cutting-edge technology and industry-standard equipment, enabling us to provide top-notch mixing and mastering services.",
    },
    {
      title: "Customized Approach",
      description:
        "We recognize that every project is unique. Our approach to mixing and mastering is highly personalized, taking into account your artistic vision and preferences.",
    },
    {
      title: "Comprehensive Services",
      description:
        "Studio Final Take offers a comprehensive range of mixing and mastering services to suit all your needs.",
    },
    {
      title: "Client-Centric Approach",
      description:
        "We believe in building strong relationships based on trust and communication. From the initial consultation to the final delivery, we keep you involved and informed throughout the process.",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>
          Best Mixing and Mastering Studio in Delhi | Studio Final Take
        </title>
        <meta
          name="description"
          content="Studio Final Take provides the best mixing and mastering services in Delhi. With advanced equipment and skilled professionals, we ensure top-notch quality for your audio projects."
        />
        <link
          rel="canonical"
          href="https://www.studiofinaltake.com/mixing-mastering-studio"
        />
        <meta
          property="og:title"
          content="Best Mixing and Mastering Services in Delhi | Studio Final Take"
        />
        <meta
          property="og:description"
          content="Studio Final Take provides the best mixing and mastering services in Delhi. With advanced equipment and skilled professionals, we ensure top-notch quality for your audio projects."
        />
        <meta
          property="og:url"
          content="https://www.studiofinaltake.com/mixing-mastering-studio"
        />
        <meta property="og:site_name" content="STUDIO FINAL TAKE" />
        <meta property="og:type" content="Local Business" />
        <meta
          name="keywords"
          content="music recording studio in delhi, mixing and mastering studio in delhi, best music recording studio, mastering studio in new delhi, recording studio in south west delhi, song recording studio in new delhi"
        />
      </Helmet>

      <ReusableBanner
        headone="Polish your sound"
        headtwo="mesmerize your listeners!"
        banner="/Images/banner/mixing-mastering-studio-banner.jpg"
        para="Elevate your sound with Studio Final Take, offering premier mixing and mastering services in Delhi. Our dedicated team of audio engineers brings years of expertise to ensure your tracks achieve professional clarity and balance. Whether you're a musician, filmmaker or content creator, we provide meticulous attention to detail and utilize state-of-the-art technology to enhance your audio quality. Trust Studio Final Take for the best mixing and mastering services in Delhi, where precision meets passion to deliver exceptional results for every project."
      />
      <WhyStudio
        img_two="/Images/mixing-mastering-studio-service-two.jpg"
        alt_one="mixing mastering-studio service"
        alt_two="mixing mastering-studio service"
        img_circle="/Images/testnew.png"
        head_sec="Best Mixing and Mastering Services in Delhi"
        para_sec="At Studio Final Take, we understand that every piece of music holds the potential to touch hearts and resonate with listeners worldwide. Our mission is to elevate your tracks to their fullest potential through our professional mixing and mastering services. Based in the vibrant city of Delhi, we take pride in offering the best mixing and mastering services, tailored to meet the unique needs of each artist, producer and musician."
        img_one="/Images/mixing-mastering-studio-service-one.jpg"
      />
      <ServiceSection
        heading="Best Mixing And Mastering Service in Delhi"
        services={services}
      />

      <WhyUs
        heading="Here’s what sets us apart as the best mixing and mastering service provider in Delhi:"
        points={points}
        image="https://www.templates.hibootstrap.com/atoli/default/assets/img/faq/faq-img3.jpg "
      />
      <CallToAction />
      {/* <CommingSoon/> */}
    </div>
  );
};

export default MixingMastering;
