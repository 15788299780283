import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const ServiceSection = ({ heading, services}) => {
  return (
    <div className="services-area-three pt-100 pb-70 section-bg">
      <Container>
        <div className="section-title text-center">
          <span className="sp-color">Our Services</span>
          <h2>{heading}</h2>
        </div>
        <Row className="pt-45">
          {services.map((service, index) => (
            <Col lg={6} md={6} key={index}>
              <div className="service-item-two">
                <img
                  src={service.image}
                  className="flaticon-voicee"
                  alt={service.alt}
                />
                <div className="content">
                  <h3>
                    <Link>{service.title}</Link>
                  </h3>
                  <p>{service.description}</p>
                  <div className="read-btn">Read More</div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ServiceSection;
