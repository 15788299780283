import React from "react";
import { FaAngleRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Gallery from "../AboutUs/Gallery";

const MainGallery = () => {
  return (
    <div>
      <div
        className="about-sec-one-img"
        style={{ background: "url(/Images/Guitars.jpg)" }}
      >
        <div className="overlay overlay-bold overlay-about"></div>
        <div className="about-sec-one-main">
          <h1 className="about-headone">Gallery</h1>
          <h6 className="about-sex-headsix">
            <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
              {" "}
              Home
            </Link>{" "}
            <FaAngleRight /> Gallery
          </h6>
        </div>
      </div>
     <div style={{marginBottom:'100px'}}>
     <Gallery/>
     </div>
    </div>
  );
};

export default MainGallery;
