import React from "react";
import "./Reusable.css";
import { Col, Container, Row } from "react-bootstrap";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { MdContactSupport } from "react-icons/md";
import { IoMdMailUnread } from "react-icons/io";
import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
    <section className="section_call_cont">
      {/* <Container> */}
       <div className="call_to_action_cont">
       <div>
          <h1 className="call_to_head">Let's innovate together!</h1>
          <p className="call_to_action_para">"Contact us now!</p>
        </div>
        <div className="call_to_act_second">
          <div className="call_to_act_sec_inner">
            <div >
             <Link className="call_to_act_inner" to="/contact" target="_blank" style={{color:'#fff',textDecoration:'none'}}>
             <MdContactSupport />
             <span>Support</span>
             </Link>
            </div>
            <div>
            <Link className="call_to_act_inner" to="tel:+918084187424" style={{color:'#fff',textDecoration:'none'}}>
            <FaPhone />
            <span>Call With Us</span>
            </Link>
            </div>
          </div>
          <div className="call_to_act_sec_inner">
            <div>
              <Link className="call_to_act_inner" to="mailto:music@studiofinaltake.com" style={{color:'#fff',textDecoration:'none'}}>
              <IoMdMailUnread />
              <span>Mail Us</span></Link>
            </div>
            <div >
              <Link className="call_to_act_inner" to="https://www.maps.app.goo.gl/9WAWoCdqaVJVac1KA" target="_blank" style={{color:'#fff',textDecoration:'none'}}>
              <FaLocationDot />
              <span>Location</span>
              </Link>
            </div>
          </div>
        </div>
       </div>
      {/* </Container> */}
    </section>
  );
};

export default CallToAction;
