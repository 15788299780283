// WhatsAppChat.js

import React from 'react';
import './Whatsapp.css'; // Import CSS for styling WhatsApp chat
// import Whatsapp from './whatsapp.png'
const WhatsApp = () => {
  // Function to handle click event on WhatsApp chat button
  const handleChatButtonClick = () => {
    // Replace this with your actual WhatsApp chat link
    window.open('https://wa.me/+918084187424', '_blank');
  };

  return (
    <div className="whatsapp-chat">
      <button className="whatsapp-chat-button" onClick={handleChatButtonClick}>
        <img src="/Images/whatsapp.png" alt="WA" />
        <span className='whatsapp_font_para'>Message Us</span>
      </button>
    </div>
  );
};

export default WhatsApp;
