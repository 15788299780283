import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./Testimonial.css";
import Quotes from "./asdf.png";
// import "./testimonial.css";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { FaRegStarHalfStroke } from "react-icons/fa6";

const Testimonial = () => {
  return (
    <div className="testimonial_main_secc">
      <h3 className="testimonial_main_div">
        Happy Artist{" "}
        <img
          src="/Images/happy.png"
          alt="studio final take happy image"
          className="review_image_section"
        />
      </h3>

      <Container>
        <Row>
          <Col md={8}>
            <Swiper
              // slidesPerView={3}
              spaceBetween={30}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  // spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              // pagination={{
              //   clickable: true,
              // }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide
                style={{
                  width: "100%",
                  height: "320px",
                }}
                className="swiper-slider-padds"
              >
                <div className="cl_lst_box">
                  <div className="client_icon hidden-xs">
                    <img
                      src={Quotes}
                      loading="lazy"
                      alt="Client Quote"
                      title="Client Quote"
                      width={64}
                      height={50}
                    />
                  </div>
                  <div className="clie_r_s">
                    <p className="clie_cnt">
                      "Best place for all the music professionals and actors
                      too. You will like a family here, Excellent environment.
                      Rates are also reasonable. Loved the quality of work.
                      Thanks Final Take team for helping me."
                    </p>
                    <p className="mt25 bb1" />
                    <div className="text-center">
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaRegStarHalfStroke color="#fde16d" size={15} />
                    </div>
                    <p className="cli_na text-center">Manav Singh</p>
                  </div>
                  <p className="clearfix" />
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide
                style={{
                  width: "100%",
                  height: "320px",
                }}
                className="swiper-slider-padds"
              >
                <div className="cl_lst_box">
                  <div className="client_icon hidden-xs">
                    <img
                      src={Quotes}
                      loading="lazy"
                      alt="Client Quote"
                      title="Client Quote"
                      width={64}
                      height={50}
                    />
                  </div>
                  <div className="clie_r_s">
                    <p className="clie_cnt">
                      Nice platform of new and talented stars, actor, Singer
                      Lyricist & music composer. Whoever has water as his
                      destination he will definitely come Because STUDIO FINAL
                      TAKE is no longer away from anyone...
                    </p>
                    <p className="mt25 bb1" />
                    <div className="text-center">
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaRegStarHalfStroke color="#fde16d" size={15} />
                    </div>
                    <p className="cli_na text-center">Vishnu Dev</p>
                  </div>
                  <p className="clearfix" />
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide
                style={{
                  width: "100%",
                  height: "320px",
                }}
                className="swiper-slider-padds"
              >
                <div className="cl_lst_box">
                  <div className="client_icon hidden-xs">
                    <img
                      src={Quotes}
                      loading="lazy"
                      alt="Client Quote"
                      title="Client Quote"
                      width={64}
                      height={50}
                    />
                  </div>
                  <div className="clie_r_s">
                    <p className="clie_cnt">
                      "Mai Dharmanand Saraswati singer and writer final take
                      studio best recording studio delhi I love you final take
                      studio and love you all teams final take production"
                    </p>
                    <p className="mt25 bb1" />
                    <div className="text-center">
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaRegStarHalfStroke color="#fde16d" size={15} />
                    </div>
                    <p className="cli_na text-center">Saraswati Swansargam</p>
                  </div>
                  <p className="clearfix" />
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide
                style={{
                  width: "100%",
                  height: "320px",
                }}
                className="swiper-slider-padds"
              >
                <div className="cl_lst_box">
                  <div className="client_icon hidden-xs">
                    <img
                      src={Quotes}
                      loading="lazy"
                      alt="Client Quote"
                      title="Client Quote"
                      width={64}
                      height={50}
                    />
                  </div>
                  <div className="clie_r_s">
                    <p className="clie_cnt">
                      "It's great studio and management are very technical
                      sound"
                    </p>
                    <p className="mt25 bb1" />
                    <div className="text-center">
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaRegStarHalfStroke color="#fde16d" size={15} />
                    </div>
                    <p className="cli_na text-center">Satya Yadav</p>
                  </div>
                  <p className="clearfix" />
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide
                style={{
                  width: "100%",
                  height: "320px",
                }}
                className="swiper-slider-padds"
              >
                <div className="cl_lst_box">
                  <div className="client_icon hidden-xs">
                    <img
                      src={Quotes}
                      loading="lazy"
                      alt="Client Quote"
                      title="Client Quote"
                      width={64}
                      height={50}
                    />
                  </div>
                  <div className="clie_r_s">
                    <p className="clie_cnt">
                      "Studio final take production is a very good recording
                      studio. There are many good people here"
                    </p>
                    <p className="mt25 bb1" />
                    <div className="text-center">
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaRegStarHalfStroke color="#fde16d" size={15} />
                    </div>
                    <p className="cli_na text-center">Raj Verma</p>
                  </div>
                  <p className="clearfix" />
                </div>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  width: "100%",
                  height: "320px",
                }}
                className="swiper-slider-padds"
              >
                <div className="cl_lst_box">
                  <div className="client_icon hidden-xs">
                    <img
                      src={Quotes}
                      loading="lazy"
                      alt="Client Quote"
                      title="Client Quote"
                      width={64}
                      height={50}
                    />
                  </div>
                  <div className="clie_r_s">
                    <p className="clie_cnt">
                      "STUDIO FINAL TAKE PRODUCTION VERY BIG PRODUCTION HOUSE
                      DELHI YAHA LOG BHI UTNI HI ACHHE HAI LOVE YOU FINAL TAKE
                      TEAM"
                    </p>
                    <p className="mt25 bb1" />
                    <div className="text-center">
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaRegStarHalfStroke color="#fde16d" size={15} />
                    </div>
                    <p className="cli_na text-center">Anil Sharma</p>
                  </div>
                  <p className="clearfix" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="cl_lst_box">
                  <div className="client_icon hidden-xs">
                    <img
                      src={Quotes}
                      loading="lazy"
                      alt="Client Quote"
                      title="Client Quote"
                      width={64}
                      height={50}
                    />
                  </div>
                  <div className="clie_r_s">
                    <p className="clie_cnt">
                      "मैं ऋतिक गुप्ता फाइनल टेक स्टूडियो के साथ लगभग एक डेढ़ साल
                      से जुड़ा हुआ हु, यहाँ काम बहुत ही उन्दा होता हैं अगर आपको
                      मेरा गाना सुनना हैं तो आप मेरे चैनल पर सुन सकते हैं.
                      यूट्यूब में सर्च करे तेरे नाल ऋतिक गुप्ता एक बार स्टूडियो
                      फाइनल टेक को जरूर विजिट करना चाहिए यहाँ के लोग भी बहुत
                      अच्छे एंड सुप्पोर्टिव है."
                    </p>
                    <p className="mt25 bb1" />
                    <div className="text-center">
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaRegStarHalfStroke color="#fde16d" size={15} />
                    </div>
                    <p className="cli_na text-center">Singer Ritik Gupta</p>
                  </div>
                  <p className="clearfix" />
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ width: "100%", height: "350px" }}>
                <div className="cl_lst_box">
                  <div className="client_icon hidden-xs">
                    <img
                      src={Quotes}
                      loading="lazy"
                      alt="Client Quote"
                      title="Client Quote"
                      width={64}
                      height={50}
                    />
                  </div>
                  <div className="clie_r_s">
                    <p className="clie_cnt">
                      "best recording studio final take gread person all team
                      yaha ke log bahut achhe hai or kam to mat puchhiy mera
                      lagbhag 10 song record huaa hai best work final take
                      production"
                    </p>
                    <p className="mt25 bb1" />
                    <div className="text-center">
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaStar color="#fde16d" size={15} />
                      <FaRegStarHalfStroke color="#fde16d" size={15} />
                    </div>
                    <p className="cli_na text-center">Arjun kashyap</p>
                  </div>
                  <p className="clearfix" />
                </div>
              </SwiperSlide>
            </Swiper>
            <p
              className="mt25 text-center testimonial-para-tag"
              style={{ color: "black" }}
            >
              <Link
                to="/studio-final-take-review"
                target="_blank"
                className="v_btn11 google v_btn111 vat"
              >
                More Satisfied Artists
              </Link>
            </p>
          </Col>
          <Col md={4}>
            <div className="cl_rgt">
              <div className="vi_ar">
                <Link
                  data-toggle="modal"
                  data-target="#vidmodal"
                  data-dismiss="modal"
                  aria-label="Video Client Testimonial"
                  role="button"
                >
                  <b className="play_icn1"></b>
                  {/* <img
                    src="https://img.youtube.com/vi/qOLWhGtnq5U/hqdefault.jpg"
                    width={480}
                    height={360}
                    alt=""
                    loading="lazy"
                    decoding="async"
                    fetchpriority="low"
                  /> */}
                  <iframe
                    className="height-width-video"
                    src="https://www.youtube.com/embed/UagqsKoPViQ"
                    title="Happy Artist at Studio Final Take Delhi"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </Link>
              </div>
              <p className="mt25 bb1" />
              <div className="pull-left">
                <p className="cli_na">Navya Gupta</p>
                <p className="client_site black">Reviews</p>
              </div>
              {/* <div className="pull-right">
                <p className="fs15 gray i mt14">Country</p>
                <p className="c_tx2 google">Delhi</p>
              </div> */}
              <div className="clearfix" />
              <p className="mt30 text-center btn-marginn">
                <Link
                  to="/studio-final-take-review"
                  target="_blank"
                  //   href="/videotestimonials.php"
                  className="v_btn google v_btn3 vat"
                >
                  More Videos
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Testimonial;
