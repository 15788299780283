import React from "react";
import "./Location.css";
const Location = () => {
  return (
    <div className="main-location-cont">
      <div style={{ width: "100%", marginTop: "10px" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.9311245496006!2d77.07959567450872!3d28.601842985475436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xacd970a201e6e4cb%3A0x6744a8301a54686c!2sStudio%20Final%20Take!5e0!3m2!1sen!2sin!4v1719995219575!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: "0", width: "100%" }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default Location;
