// import React, { useRef, useState } from "react";
// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";
// import "./SectionTwo.css";

// import "swiper/css/pagination";

// // import required modules
// import { Autoplay, Pagination, Navigation } from "swiper/modules";
// import { Container } from "react-bootstrap";
// import { FiArrowLeft } from "react-icons/fi";
// import Mic from "./mic.jpeg";
// import MusicDest from "./musicDestri.jpg";
// import MusicProd from "./MusicProd.jpg";
// import MusicMaster from "./musicMaster.jpg";
// import SoundRec from "./soundRec.jpg";
// import VideoProd from "./videoProd.jpg";
// import VoiceOver from "./voiceOver.jpg";
// const SectionTwo = () => {
//   return (
//     <div
//       style={{ backgroundColor: "#f0f0f3", paddingTop: "50px" }}
//       className="services-sc services-slider layout-product ltx-style-gray"
//     >
//       <Container style={{ width: "100%", marginBottom: "60px" }}>
//         <div>
//           <div className="sheading">What we do</div>
//           <div className="mheading">Studio Services</div>
//         </div>
//         <Swiper
//           slidesPerView={1}
//           spaceBetween={30}
//           autoplay={{
//             delay: 2500,
//             disableOnInteraction: false,
//           }}
//           // pagination={{
//           //   clickable: true,
//           // }}
//           breakpoints={{
//             640: {
//               slidesPerView: 1,
//               spaceBetween: 20,
//             },
//             768: {
//               slidesPerView: 2,
//               spaceBetween: 40,
//             },
//             1024: {
//               slidesPerView: 4,
//               spaceBetween: 20,
//             },
//           }}
//           navigation={true}
//           modules={[Autoplay, Navigation]}
//           // modules={[Pagination]}
//           className="mySwiper"
//         >
//           <SwiperSlide
//             className="layout-product"
//             data-swiper-slide-index={0}
//             style={{ width: 570, marginRight: 30 }}
//           >
//             <div className="ltx-inner">
//               {" "}
//               <Link className="photo">
//                 <img
//                   width={750}
//                   height={525}
//                   src={SoundRec}
//                   className="attachment-recond-service size-recond-service wp-post-image"
//                   alt=""
//                 />
//                 <span className="ltx-plus" />
//               </Link>
//               <div className="description">
//                 {" "}
//                 <Link className="header">
//                   <h5 className="header">Sound Recording</h5>{" "}
//                 </Link>
//                 <p className="text-page-section">
//                   {" "}
//                   Studio Final Take offers professional sound recording services
//                   in a state-of-the-art studio, equipped with cutting-edge
//                   technology for high-quality audio production across various
//                   genres and projects.
//                 </p>{" "}

//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide
//             className="layout-product"
//             data-swiper-slide-index={0}
//             style={{ width: 570, marginRight: 30 }}
//           >
//             <div className="ltx-inner">
//               {" "}
//               <Link className="photo">
//                 <img
//                   width={750}
//                   height={525}
//                   src={MusicProd}
//                   className="attachment-recond-service size-recond-service wp-post-image"
//                   alt=""
//                 />
//                 <span className="ltx-plus" />
//               </Link>
//               <div className="description">
//                 {" "}
//                 <Link className="header">
//                   <h5 className="header">Music Production</h5>{" "}
//                 </Link>
//                 <p className="text-page-section">
//                   {" "}
//                   Studio Final Take, a leading music production studio,
//                   providing comprehensive services to artist's needs. From
//                   recording to mixing and mastering, we ensure top quality for
//                   every musical project.
//                 </p>{" "}

//               </div>
//             </div>
//           </SwiperSlide>

//           <SwiperSlide
//             className="layout-product"
//             data-swiper-slide-index={0}
//             style={{ width: 570, marginRight: 30 }}
//           >
//             <div className="ltx-inner">
//               {" "}
//               <Link className="photo">
//                 <img
//                   width={750}
//                   height={525}
//                   src={VideoProd}
//                   className="attachment-recond-service size-recond-service wp-post-image"
//                   alt=""
//                 />
//                 <span className="ltx-plus" />
//               </Link>
//               <div className="description">
//                 {" "}
//                 <Link className="header">
//                   <h5 className="header">Video Production</h5>{" "}
//                 </Link>
//                 <p className="text-page-section">
//                   {" "}
//                   Studio Final Take excels as a premier video production studio,
//                   offering end-to-end services from concept to final cut. With
//                   state-of-the-art equipment and creative expertise, we bring
//                   your vision to life with precision and flair.
//                 </p>{" "}

//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide
//             className="layout-product"
//             data-swiper-slide-index={0}
//             style={{ width: 570, marginRight: 30 }}
//           >
//             <div className="ltx-inner">
//               {" "}
//               <Link className="photo">
//                 <img
//                   width={750}
//                   height={525}
//                   src={MusicMaster}
//                   className="attachment-recond-service size-recond-service wp-post-image"
//                   alt=""
//                 />
//                 <span className="ltx-plus" />
//               </Link>
//               <div className="description">
//                 {" "}
//                 <Link className="header">
//                   <h5 className="header">Mixing & Mastering</h5>{" "}
//                 </Link>
//                 <p className="text-page-section">
//                   {" "}
//                   Studio Final Take provides professional mixing and mastering
//                   services, ensuring your music achieves optimal clarity,
//                   balance and sonic excellence. Trust us to elevate your tracks
//                   to their fullest potential.
//                 </p>{" "}

//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide
//             className="layout-product"
//             data-swiper-slide-index={0}
//             style={{ width: 570, marginRight: 30 }}
//           >
//             <div className="ltx-inner">
//               {" "}
//               <Link className="photo">
//                 <img
//                   width={750}
//                   height={525}
//                   src={MusicDest}
//                   className="attachment-recond-service size-recond-service wp-post-image"
//                   alt=""
//                 />
//                 <span className="ltx-plus" />
//               </Link>
//               <div className="description">
//                 {" "}
//                 <Link className="header">
//                   <h5 className="header">Music Distribution</h5>{" "}
//                 </Link>
//                 <p className="text-page-section">
//                   {" "}
//                   Studio Final Take offers comprehensive music distribution
//                   services, ensuring your tracks reach global platforms
//                   efficiently. From digital releases to licensing, trust us to
//                   amplify your music's reach and visibility effectively.
//                 </p>{" "}

//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide
//             className="layout-product"
//             data-swiper-slide-index={0}
//             style={{ width: 570, marginRight: 30 }}
//           >
//             <div className="ltx-inner">
//               {" "}
//               <Link className="photo">
//                 <img
//                   width={750}
//                   height={525}
//                   src={VoiceOver}
//                   className="attachment-recond-service size-recond-service wp-post-image"
//                   alt=""
//                 />
//                 <span className="ltx-plus" />
//               </Link>
//               <div className="description">
//                 {" "}
//                 <Link className="header">
//                   <h5 className="header">Voice Over & Dubbing</h5>{" "}
//                 </Link>
//                 <p className="text-page-section">
//                   {" "}
//                   Studio Final Take offers comprehensive music distribution
//                   services, ensuring your tracks reach global platforms
//                   efficiently. From digital releases to licensing, trust us to
//                   amplify your music's reach and visibility effectively.
//                 </p>{" "}

//               </div>
//             </div>
//           </SwiperSlide>
//         </Swiper>
//       </Container>
//     </div>
//   );
// };

// export default SectionTwo;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import "./SectionTwo.css";
import { Link } from "react-router-dom";

const SectionTwo = () => {
  const truncateText = (content, wordLimit) => {
    const words = content.split(" ");
    if (words.length > wordLimit) {
      return isSmallScreen
        ? words.slice(0, wordLimit).join(" ") + "..."
        : words.join(" ");
    }
    return content;
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 512);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 512);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const cardData = [
    {
      links: "/sound-recording-studio",
      imageSrc: "/Images/service/sound-recording-studio.jpg",
      alt:"sound recording studio",
      title: "Sound Recording",
      content:
        "Studio Final Take offers professional sound recording services in a state-of-the-art studio, equipped with cutting-edge technology for high-quality audio production across various genres and projects",
    },
    {
      links: "/music-production-studio",
      imageSrc: "/Images/service/music-production-studio.jpg",
      alt:"music production studio",
      title: "Music Production",
      content:
        "Studio Final Take, a leading music production studio, providing comprehensive services to artist's needs. From recording to mixing and mastering, we ensure top quality for every musical project",
    },
    {
      links: "https://www.finaltakeproduction.com/ft-films",
      imageSrc: "/Images/service/video-production-company.jpg",
      alt:'video production company',
      title: "Video Production",
      content:
        "Studio Final Take excels as a premier video production studio, offering end-to-end services from concept to final cut. With state-of-the-art equipment and creative expertise, we bring your vision to life with precision and flair",
    },
    {
      links: "/mixing-mastering-studio",
      imageSrc: "/Images/service/mixing-mastering-company.jpg",
      alt:"mixing mastering company",
      title: "Mixing & Mastering",
      content:
        "Studio Final Take provides professional mixing and mastering services, ensuring your music achieves optimal clarity, balance and sonic excellence. Trust us to elevate your tracks to their fullest potential",
    },
    {
      links: "https://fttunes.com/",
      imageSrc: "/Images/service/music-distribution-company.jpg",
      alt:"music distribution company",
      title: "Music Distribution",
      content:
        "Studio Final Take offers comprehensive music distribution services, ensuring your tracks reach global platforms efficiently. From digital releases to licensing, trust us to amplify your music's reach and visibility effectively",
    },
    {
      links: "/voice-over-dubbing-studio",
      imageSrc: "/Images/service/voice-over-dubbing.jpg",
      alt:"voice over dubbing",
      title: "Voice Over & Dubbing",
      content:
        "Studio Final Take offers comprehensive music distribution services, ensuring your tracks reach global platforms efficiently. From digital releases to licensing, trust us to amplify your music's reach and visibility effectively",
    },
  ];

  return (
    <div className="section_two_section" style={{ background: "#f5f5f5" }}>
      <Container>
        <div>
          {" "}
          <div className="sheadingss">What we do</div>
          <div className="mheadingss">Studio Services</div>{" "}
        </div>
        <Row>
          {cardData.map((item, index) => (
            <Col md={6} sm={12} key={index} className="mb-4">
              <Card
                className="h-100 box-shadow-container"
                style={{ padding: "10px" }}
              >
                <Row noGutters className="card-items-row">
                  <Col xs={5}>
                    <Link to={item.links}>
                      <div className="slider-remove-div">
                        <Card.Img
                          src={item.imageSrc}
                          alt={item.alt}
                          className="card-image-itemss"
                        />
                      </div>
                    </Link>
                  </Col>
                  <Col xs={7}>
                    <Card.Body>
                      <Link
                        to={item.links}
                        style={{ textDecoration: "none", color: "#212529" }}
                      >
                        <Card.Title className="card-item-titles">
                          {item.title}
                        </Card.Title>
                      </Link>
                      <Card.Text className="card-items-text">
                        {truncateText(item.content, 15)}{" "}
                        <Link
                          to={item.links}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p
                            style={{ color: "#1b42b3" }}
                            className="section_two_read"
                          >
                            ...Read More
                          </p>
                        </Link>{" "}
                        {isSmallScreen && (
                          <Link to={item.links} target="_blank">
                            <span className="card-item-read-more">
                              Read More
                            </span>
                          </Link>
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default SectionTwo;
