import React from "react";
import { Container } from "react-bootstrap";
import Video from "./video.mp4";
import "./Section.css";
const SectionSix = () => {
  return (
    <div className="videoClass" style={{ background: "#f5f5f5" }}>
      <Container className="video_container">
        <div className="yutu-video-cont">
          <h1 className="sheading">
            {/* UNLEASH YOUR IMAGINATION WITH FINAL TAKE STUDIO */}
            Best Music Recording Studio In Delhi NCR
          </h1>
          <p className="mheading">
            {" "}
            Studio Final Take is a distinguished music studio and film
            production house based in Delhi, renowned for delivering top-tier
            music recording, mixing and mastering services and dubbing. With a
            mission to nurture creativity and excellence in the music and film
            industries, Studio Final Take has established itself as a beacon for
            artists and filmmakers seeking high-quality production services.
          </p>
        </div>
        <div className="wpb_wrapper">
          <div className="gt-video-player gt-style-1 gt-background-position-center-center gt-background-attachment-scroll gt-background-size-cover gt-background-repeat-no-repeat">
            {/* <video className="w-100" id="myVideo" autoPlay muted loop>
                            <source src="https://youtu.be/CrGYgmBt9kw"  type="video/mp4" />
                            Your browser does not support the HTML5 video tag.
                        </video> */}
            <iframe
              className="iframe-image-sec"
              src="https://www.youtube.com/embed/CrGYgmBt9kw"
              title="STUDIO FINAL TAKE | BEST MUSIC RECORDING STUDIO IN DELHI | AUDIO AND VIDEO PRODUCTION HOUSE IN DELHI"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SectionSix;
