import React from "react";

const Equipment = () => {
  return (
    <>
      <section className="equipment-about-main" style={{background:'#f5f5f5'}}>
        <div className="container pt-5 pb-1">
          <h2 className="mb-5 text-center equipment-about-head">
            World Class Professional Recording Equipment
          </h2>
          <p className="mb-3 text-center equipment-about-para">
            The studio is equipped with state of the art, world-class equipment
            including a Professional M-Audio Monitors, Rode NT2-A Large
            Diaphragm 3 Polar Pattern Studio Condenser, Motu 8pre 16 x 12 USB
            Audio Interface with 8 Mic Inputs, Cubase: Music Production Software
            | Steinberg, Shure Professional Studio Headphones, Sound-Proof Vocal
            Booth and many more.
          </p>
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="equipment_card">
                <img
                  src="/Images/recording-equipment/m-audio-studio-monitor.png"
                  alt="m audio studio monitor"
                />
                <h4>Professional M-Audio Monitors</h4>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="equipment_card">
                <img
                  src="/Images/recording-equipment/rode-nt2a-mic.jpg"
                  alt="rode nt2a mic"
                />
                <h4>
                  Rode NT2-A Large Diaphragm 3 Polar Pattern Studio Condenser
                </h4>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="equipment_card">
                <img
                  src="/Images/recording-equipment/motu-sondcard-828x-display.jpg"
                  alt="motu sondcard 828x display"
                />
                <h4>Motu 8pre 16 x 12 USB Audio Interface with 8 Mic Inputs</h4>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="equipment_card">
                <img
                  src="/Images/recording-equipment/Cubase-Music-Production-Software-Steinberg.png"
                  alt="cubase music production software steinberg"
                />
                <h4>Cubase: Music Production Software | Steinberg</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Equipment;
