import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";

const WhyStudio = ({ img_one, img_two, img_circle, head_sec, para_sec,alt_one,alt_two }) => {
  return (
    <div className="accommodations__two section-padding">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-5 order-last order-lg-first">
            <div className="accommodations__two-left">
              <img src={img_one} alt={alt_one} className="accomodation_image-width" />
              {/* <div className="accommodations__two-left-roll">
                <img className="content__roll " src={img_circle} alt="" />
              </div> */}
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 lg-mb-30">
            <div className="accommodations__two-title">
              <span className="subtitle__one">Accommodations</span>
              <h1>{head_sec}</h1>
              <p>{para_sec}</p>
              <Link className="theme-border-btn" to="/contact" target="_blank">
                Contact Us
                <HiOutlineArrowNarrowRight style={{ marginLeft: "10px" }} />
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-7 md-mb-30">
            <div className="accommodations__two-right">
              <img
                src={img_two}
                className="accomodation_image-width_two"
                alt={alt_two}
              />
              <div className="accommodations__two-right-bottom">
                {/* <div className="accommodations__two-right-bottom-signature">
                    <img src={img_three} alt="" />
                  </div> */}
                {/* <div className="accommodations__two-right-bottom-author">
                    <h5>David Beckham</h5>
                    <span>Founder CEO</span>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyStudio;
