import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, EffectCoverflow, Pagination } from "swiper/modules";
import "./Gallery.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
// import { EffectCoverflow, Pagination, Navigation } from "swiper";
const Gallery = () => {
  const images = [
    { img: "/Images/studio/studio-final-take-1.jpg", alt: "studio final take" },
    { img: "/Images/studio/studio-final-take-2.jpg", alt: "studio final take" },
    { img: "/Images/studio/studio-final-take-3.jpg", alt: "studio final take" },
    { img: "/Images/studio/studio-final-take-4.jpg", alt: "studio final take" },
    { img: "/Images/studio/studio-final-take-5.jpg", alt: "studio final take" },
    { img: "/Images/studio/studio-final-take-6.jpg", alt: "studio final take" },
    { img: "/Images/studio/studio-final-take-7.jpg", alt: "studio final take" },
    { img: "/Images/studio/studio-final-take-8.jpg", alt: "studio final take" },
    { img: "/Images/studio/studio-final-take-9.jpg", alt: "studio final take" },
  ];
  const swiperRef = useRef(null);
  return (
    <div>
      <div
        className="container gallery_section_cont"
        style={{ position: "relative" }}
      >
        <div className="gallery-section-sec">
          {" "}
          <div className="sheadingss">Gallery</div>
          <div className="mheadingss">Studio Gallery</div>{" "}
        </div>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 90,
            modifier: 2.5,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          // navigation={{
          //   nextEl: ".ight-arrow",
          //   prevEl: ".eft-arrow",
          //   clickable: true,
          // }}

          modules={[EffectCoverflow, Navigation, Pagination]}
          className="swiper_container"
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image.img}
                alt={image.alt}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="custom-next2"
          color="#d70d57"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <FaChevronRight />
        </div>
        <div
          className="custom-prev2"
          color="#d70d57"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <FaChevronLeft />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
