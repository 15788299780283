import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./SectionSeven.css";

import { Link } from "react-router-dom";
const SectionSeven = () => {
  return (
    <div className="SpeakerClass">
      <Container style={{ position: "relative" }}>
        <div style={{ textAlign: "center" }}>
          <h2 className="studio-seven-sec">
            World Class Professional Recording Equipment
          </h2>
          {/* <p className="seven-para">
            Fusce et ligula scelerisque, facilisis elit eget, semper lectus.
            Donec varius arcu augue,{" "}
            <span style={{ color: "#d70d57" }}>
              in egestas leo ornare eget.
            </span>{" "}
            Phasellus commodo pretium ante a congue. Ut quam dolor, egestas.
          </p> */}
        </div>
        <Row>
          <Col lg={3}>
            <ul className="studio-seven-sec-ul">
              <li>
                <div
                  data-mh="ltx-block-icon-in-1246"
                  className="in matchHeight"
                  style={{}}
                >
                  <span
                    className="ltx-icon icon-live_instruments bg-transparent"
                    data-mh="ltx-icon-span-like_sc_block_icon_781289530"
                    style={{}}
                  />
                  <div className="block-right block-right-display">
                    <div>
                      <img
                        src="/Images/Equipment/live-instrument.png"
                        alt="live instrument"
                        style={{ width: "11rem", color: "#d" }}
                      />
                    </div>
                    <div>
                      <h6 className="header">Live Instruments</h6>
                      <div className="descr">
                        Studio Final Take offers live instruments for music,
                        enhancing recordings with authentic sounds and
                        unparalleled quality.
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  data-mh="ltx-block-icon-in-1246"
                  className="in matchHeight"
                  style={{}}
                >
                  <span
                    className="ltx-icon icon-live_instruments bg-transparent"
                    style={{}}
                  />
                  <div className="block-right block-right-display">
                    <div>
                      <img
                        src="/Images/Equipment/rta-microphone.png"
                        alt="rta microphone"
                        style={{ width: "8rem", color: "#d" }}
                      />
                    </div>
                    <div>
                      <h6 className="header">Rode NT2-A Microphone</h6>
                      <div className="descr">
                        Studio Final Take uses the Rode NT2-A Microphone for
                        superior audio clarity, capturing every nuance with
                        professional precision..
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="in matchHeight" style={{}}>
                  <span
                    className="ltx-icon icon-live_instruments bg-transparent"
                    style={{}}
                  />
                  <div className="block-right block-right-display">
                    <div>
                      <img
                        src="/Images/Equipment/high-quality-earphone.png"
                        alt="high quality earphone"
                        style={{ width: "7.5rem", color: "#d" }}
                      />
                    </div>
                    <div>
                      <h6 className="header">High Quality Headphone</h6>
                      <div className="descr">
                        Studio Final Take employs high-quality headphones for
                        impeccable sound monitoring, ensuring precise and
                        professional audio production.
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="in matchHeight" style={{}}>
                  <span
                    className="ltx-icon icon-live_instruments bg-transparent"
                    style={{}}
                  />
                  <div className="block-right block-right-display">
                    <div>
                      <img
                        src="/Images/Equipment/keyboard-controller.png"
                        alt="keyboard controller"
                        style={{ width: "8rem", color: "#d" }}
                      />
                    </div>
                    <div>
                      <h6 className="header">MIDI Keyboard Controller</h6>
                      <div className="descr">
                        Studio Final Take uses MIDI keyboard controllers for
                        dynamic music production, offering precision and
                        versatility in every recording session.
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </Col>
          <Col lg={6} className="music-amplifier-margin">
            <div className="main-seven" style={{ position: "relative" }}>
              <div className="wpb_single_image wpb_content_element vc_align_center">
                <figure className="wpb_wrapper vc_figure">
                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                    <img
                      width={493}
                      height={452}
                      src="/Images/studio-final-take-clipart.jpg"
                      className="vc_single_image-img attachment-full"
                      alt="studio final take clipart"
                    />
                  </div>
                </figure>
              </div>
              <div className="wpb_single_image wpb_content_element vc_align_center   ltx-waves">
                <figure className="wpb_wrapper vc_figure">
                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                    <img
                      width={600}
                      height={322}
                      src="/Images/base-master.png"
                      className="vc_single_image-img attachment-full"
                      alt="base master"
                    />
                  </div>
                </figure>
              </div>
              <div className="vc_column-inner"></div>
            </div>
          </Col>
          <Col lg={3}>
            <ul>
              <li>
                <div className="in matchHeight">
                  <span
                    className="ltx-icon icon-live_instruments bg-transparent"
                    data-mh="ltx-icon-span-like_sc_block_icon_781289530"
                  />
                  <div className="block-right block-right-display">
                    <div>
                      <img
                        src="/Images/Equipment/audio-station.png"
                        alt="audio station"
                        style={{ width: "8rem", color: "#d" }}
                      />
                    </div>
                    <div>
                      <h6 className="header">Digital Audio Workstation</h6>
                      <div className="descr">
                        Studio Final Take utilizes advanced digital audio
                        workstations for precise, high-quality music production,
                        ensuring professional results every tim
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="in matchHeight">
                  <span className="ltx-icon icon-live_instruments bg-transparent" />
                  <div className="block-right block-right-display">
                    <div>
                      <img
                        src="/Images/Equipment/usb-audio-interface.png"
                        alt="usb audio interface"
                        style={{ width: "8rem", color: "#d" }}
                      />
                    </div>
                    <div>
                      <h6 className="header">
                        Motu 8pre 16 x 12 USB Audio Interface
                      </h6>
                      <div className="descr">
                        Studio Final Take utilizes the Motu 8pre 16 x 12 USB
                        Audio Interface for superior multi-channel recording and
                        high-quality audio production.
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="in matchHeight" style={{}}>
                  <span
                    className="ltx-icon icon-live_instruments bg-transparent"
                    data-mh="ltx-icon-span-like_sc_block_icon_781289530"
                    style={{}}
                  />
                  <div className="block-right block-right-display">
                    <div>
                      <img
                        src="/Images/Equipment/m-audio-studio.png"
                        alt="m audio studio"
                        style={{ width: "8rem", color: "#d" }}
                      />
                    </div>
                    <div>
                      <h6 className="header">M-Audio Studio Monitor</h6>
                      <div className="descr">
                        Studio Final Take uses premium studio monitors to
                        deliver precise sound quality, ensuring accurate mixing
                        and mastering for all projects.
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  data-mh="ltx-block-icon-in-1246"
                  className="in matchHeight"
                >
                  <span className="ltx-icon icon-live_instruments bg-transparent" />
                  <div className="block-right block-right-display">
                    <div>
                      <img
                        src="/Images/Equipment/cubase.png"
                        alt="cubase"
                        style={{ width: "8rem", color: "#d" }}
                      />
                    </div>
                    <div>
                      <h6 className="header"> CUBASE 13 </h6>
                      <div className="descr">
                        Studio Final Take utilizes Cubase 13 for advanced music
                        production, offering comprehensive tools and features
                        for creative freedom and professional results.
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </Col>
          {/* <div className="wpb_wrapper">
            <div
              className="ltx-parallax-slider-wrapperss ltx-parallax-slider"
             
              
            >
              {" "}
              <img
                className="ltx-floating-image"
                alt="about-guitar"
                src="/Images/about-guitar.png"
              />
            </div>
          </div> */}
        </Row>
      </Container>
    </div>
  );
};

export default SectionSeven;
