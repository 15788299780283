import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Reusable.css";
const WhyUs = ({ heading, points, image }) => {
  return (
    <div className="faq-area pt-100 pb-70 ">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg={6}>
            <div className="faq-content faq-content-bg2">
              <div className="section-titless">
                <span className="sp-color">Why Choose Studio Final Take?</span>
                <h2>{heading}</h2>
                {points.map((item, index) => (
                  <div className="why_us_inner_div" key={index}>
                    <p className="first_para">{item.title}</p>
                    <p className="second_para">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="faq-img-3">
              <img src='/Images/why-choose-studio-final-take.jpg' alt="why choose studio final take" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhyUs;
