import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const ImageModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Check if the modal has already been shown
    const hasShownModal = sessionStorage.getItem('hasShownModal');

    if (!hasShownModal) {
      const timer = setTimeout(() => {
        setShowModal(true);
        // Set the flag in localStorage to mark the modal as shown
        sessionStorage.setItem('hasShownModal', 'true');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Body className="p-0">
        <div style={{ position: "relative" }}>
          <img
            className="handle-pre-banner-modal"
            src="/Images/music-popup.jpg"
            alt="studio final take music offer"
          />
          <button className="close-btn-modal" onClick={handleClose}>
            &times;
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
